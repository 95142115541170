import { Injectable } from '@angular/core';

@Injectable()
export class PrivateDataShare {
    globalPolicyNumber: any
    globalPolicyDetails: any;
    globalLoginErrorMsgs: any;
    globalFileUploadSize: any;

    setPolicyNumber(policyNo){
        this.globalPolicyNumber = policyNo;
    }
    
    getPolicyNumber(){
        // if()
        console.log(this.globalPolicyDetails)
        return this.globalPolicyNumber;
    }
    
    setPolicyDetails(data){
        console.log(data)
        this.globalPolicyDetails = data;
    }

    getPolicyDetails(){
        return this.globalPolicyDetails;
    }

    setLoginErrorMessages(data){
        this.globalLoginErrorMsgs = data; 
    }

    getLoginErrorMessages(){
        return this.globalLoginErrorMsgs;
    }

    setFileUploadSize(size){
        this.globalFileUploadSize = size
    }

    getFileUploadSize(){
        return this.globalFileUploadSize
    }

}
