import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-billing-card',
  templateUrl: './billing-card.component.html',
  styleUrls: ['./billing-card.component.scss']
})
export class BillingCardComponent implements OnInit {
  @Input() policyIndex: any;
  @Input() policyNumber: any;
  @Input() billingDetails: any
  billingObj: any
  policyObj = []
  // policyNumber : any;
  styling =this.utilsService.getItemfromSession('themeObject')

  constructor(public utilsService: UtilsService, private route: ActivatedRoute, private router: Router) {
    // this.policyNumber = this.privateDataShare.getPolicyNumber()
   }

  ngOnInit(): void { 
    // this.getAllPolicies();
  }
  
  // getAllPolicies(){
  //   if(sessionStorage.getItem('policyDetails') ){
  //     this.policyObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
  //   } else{
  //     return null
  //   }
  // }

  ngOnChanges() {
    if(this.billingDetails.length)
    this.billingObj = this.billingDetails.find(resp => resp.policyNumber == this.policyNumber)
  }
  
  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

}
