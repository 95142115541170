<!-- <div class="login-box card" style="margin-top: 5%;">
    <div class="card-body" style="background-color: #eef5f9;">
        <form [formGroup]="forgotPasswordForm" autocomplete="false" (ngSubmit)="navLogin()" class="form-horizontal form-material" id="forgotPasswordform">
            <div class="form-group m-t-20">
                <div class="col-lg-12">
                    <mat-form-field style="width: 100%">
                        <input matInput formControlName="username" type="text" placeholder="{{'USERNAME' | translate}} *" [ngStyle]="styling?.fieldValueInput"/>
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="!forgotPasswordForm.get('username').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'Username'} }}</p>
                </div>
            </div>
            <div class="form-group text-center m-t-20">
                <div class="col-lg-12">
                    <button class="btn btn-info btn-block waves-effect waves-light" type="submit">Get Verification Code</button>
                </div>
            </div>
            <div class="col-lg-12 d-flex no-block align-items-center">
                <div class="ml-auto" style="cursor: pointer;" (click)="navLocation('login')">
                    {{'SIGN_IN' | translate}}
                </div>
            </div>
        </form>
    </div>
</div> -->


<div class="card get-quote-container zero-margin">
    <div class="text-left">
        <h4 class="text-center p-t-20 header-four-l" [ngStyle]="styling?.pageTitle">{{titleHeader[stepper.selectedIndex]}} </h4>
        <div class="col-lg-12 outerbox-padding">
            <mat-horizontal-stepper #stepper class="zero-padding">
                <mat-step [stepControl]="forgotPasswordForm">
                    <div class="col-md-4 offset-md-4">
                        <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">We'll send a one-time verification code to the email associated with your account.</p>
                    </div>
                    <form [formGroup]="forgotPasswordForm" class="form-horizontal form-material" id="forgotPasswordform">
                        <div class="form-group m-t-20">
                            <div class="col-md-4 offset-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="username" type="text"  placeholder="{{'USERNAME' | translate}} *" [ngStyle]="styling?.fieldValueInput"/>
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" *ngIf="!forgotPasswordForm.get('username').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'Username'} }}</p>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <div class="col-md-4 offset-md-4">
                                <div class="custom-title-info account-title">How would you like to receive your verification code?</div>
                                <div class="d-flex">
                                    <div style="width: 100px;">
                                        <input type="radio" value="mobile"  formControlName="communication" id="mobile" class="radio-col-light-blue" />
                                        <label for="mobile">Mobile</label>
                                    </div>
                                    <div style="width: 100px;">
                                        <input type="radio" value="email"  formControlName="communication" id="email" class="radio-col-light-blue"/>
                                        <label for="email">Email</label>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div> -->
                    </form>
                </mat-step>
                <mat-step [stepControl]="otpForm">
                    <form [formGroup]="otpForm" class="form-horizontal form-material" id="otpForm">
                        <div class="form-group m-t-20">
                            <div class="col-md-4 offset-md-4 p-b-20">
                                <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">We've sent you an email with your verification code. This email might take a few minutes to reach your inbox.</p>
                            </div>
                            <div class="col-md-4 offset-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="otp" type="text" placeholder="Enter Verification Code *" numbersOnly maxlength="6"[ngStyle]="styling?.fieldValueInput"/>
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" *ngIf="!otpForm.get('otp').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'OTP'} }}</p>
                            </div>
                            <div class="col-md-4 offset-md-4">
                                <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">If you didn't receive a verification code, <span class="custom-link account-title hyperlinks" [ngStyle]="styling?.hyperlink" (click)="reSubmitOtp('true')">click here</span> to request a new one after <countdown #cd [config]="{leftTime: 60,  notify:1, format: 'ss'}" (event)="handleEvent($event)"></countdown> seconds.</p>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="passwordForm">
                    <form [formGroup]="passwordForm" class="form-horizontal form-material" style="margin: 40px 0 20px 0px" id="passwordForm">
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field style="width: 100%">
                                <input 
                                matInput 
                                formControlName="NewPassword" 
                                [type]="showPassword ? 'password': 'text'" 
                                (input)="inputChange('NewPassword')"
                                [ngStyle]="styling?.fieldValueInput"
                                placeholder="{{'NEW_PASSWORD' | translate}} *" />
                                <span matSuffix>
                                    <a id='signup_togglePassword' class='show-password-data m-r-10' (click)="viewPassword()">
                                        <i [ngStyle]="styling?.toolTip"
                                        [ngClass]="showPassword?'fa fa-eye': 'fa fa-eye-slash'"></i>
                                    </a>
                                </span>
                                <span matSuffix popoverTitle="Password Format" [popover]="passwordHint" container="body" triggers="mouseenter:mouseleave">
                                    <a [ngStyle]="styling?.toolTip" class="fas fa-question-circle"></a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text" *ngIf="!passwordForm.get('NewPassword').valid && isSubmitted">{{passwordError}}</p>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <mat-form-field style="width: 100%">
                                <input 
                                matInput 
                                formControlName="confirmPassword" 
                                [type]="showPassword ? 'password': 'text'" 
                                (input)="inputChange('confirmPassword')"
                                [ngStyle]="styling?.fieldValueInput"
                                placeholder="{{'CONFIRM_PASSWORD' | translate}} *" />
                                <span matSuffix>
                                    <a id='signup_togglePassword' class='show-password-data m-r-10' (click)="viewPassword()">
                                        <i [ngStyle]="styling?.toolTip" [ngClass]="showPassword?'fa fa-eye': 'fa fa-eye-slash'"></i>
                                    </a>
                                </span>
                                <span matSuffix popoverTitle="Password Format" [popover]="passwordHint" container="body" triggers="mouseenter:mouseleave">
                                    <a [ngStyle]="styling?.toolTip" class="fas fa-question-circle"></a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text" *ngIf="passwordForm.controls.confirmPassword.errors?.required && isSubmitted">Re type password is required</p>
                            <p class="error-class mat-angular-error-text" *ngIf="passwordForm.controls.confirmPassword.errors?.MatchPassword && isSubmitted">The passwords you entered do not match. Please retry.</p>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <div class="form-group m-t-20 m-b-20 text-center">
                        <div class="col-md-4 offset-md-4">
                            <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">You may login with your new password.</p>
                        </div>
                    </div>
                    <div class="text-center p-b-40 p-t-20">
                        <button class="btn btn-info custom-button-info buttons" type="button" (click)="navLogin()"
                        [ngStyle]="styling?.button?.buttonNavigation">Go To Log In</button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>

            <div class="col-md-4 offset-md-4" *ngIf="stepper.selectedIndex < 3">
                <div class="row" style="margin: 40px 0; justify-content: center;">
                    <button class="btn btn-warning custom-button-info buttons col-lg-3" type="button"
                    (click)="navLogin()"
                    [ngStyle]="styling?.button?.buttonNegative">{{('CANCEL' | translate) }}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button class="btn btn-info custom-button-info buttons col-lg-4" type="button"
                        (click)="goForward(stepper)"
                        [ngStyle]="styling?.button?.buttonPositive">{{buttonTxt[stepper.selectedIndex ]}}</button>
                </div>
            </div>

            <!-- <div class="text-center p-b-40" *ngIf="stepper.selectedIndex < 3">
                <button class="btn btn-warning custom-button-info buttons m-r-20" type="button"
                    (click)="navLogin()"
                    [ngStyle]="styling?.button?.buttonNegative">{{('CANCEL' | translate) }}</button>
                <button class="btn btn-info custom-button-info buttons" type="button"
                    (click)="goForward(stepper)"
                    [ngStyle]="styling?.button?.buttonPositive">{{buttonTxt[stepper.selectedIndex ]}}</button>
            </div> -->
        </div>
    </div>
</div>

<ng-template #passwordHint>
    <ul style="padding-left: 25px;">
        <li>You can use the following special characters in your password: <span [innerHTML]="characterPassword"></span></li>
        <li>Password must be at least 8 characters long, and have at least one letter and one number</li>
        <li>Passwords are case sensitive</li>
    </ul>
</ng-template>