
<aside class="left-sidebar">
    <div class="scroll-sidebar">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li (click)="navigateTo('/home/dashboard')" [ngClass]="{'active': this.router.url.includes('/home/dashboard')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-home text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'HOMEPAGE' | translate}} </span>
                    </a>
                </li>
                
                <li *ngIf="displayButton.policyView" (click)="navigateTo('/home/view-policies')" [ngClass]="{'active': this.router.url.includes('/home/view-policies')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-shield-alt text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'MY_POLICIES' | translate}} </span>
                    </a>
                </li>
                <li *ngIf="displayButton.billingView" (click)="navigateTo('/home/my-billing-view')" [ngClass]="{'active': this.router.url.includes('/home/my-billing-view')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-file-invoice-dollar text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'MY_BILLING' | translate}} </span>
                    </a>
                </li>
                <li *ngIf="displayButton.claimView" (click)="navigateTo('/home/my-claims/list')" [ngClass]="{'active': this.router.url.includes('/home/my-claims')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-file-medical text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'MY_CLAIMS' | translate}} </span>
                    </a>
                </li>
                <li (click)="navigateTo('/home/my-account-view')" [ngClass]="{'active': this.router.url.includes('/home/my-account-')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-user-circle text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'MY_ACCOUNT' | translate}}</span>
                    </a>
                </li>
				<!--
                <li *ngIf="displayButton.quoteView" (click)="navigateTo('/home/get-quote')" [ngClass]="{'active': this.router.url.includes('/home/get-quote')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-user-circle text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'GET_QUOTE' | translate}}</span>
                    </a>
                </li>
				<-->
				<!--
                <li  *ngIf="displayButton.styleConfView" (click)="navigateTo('/home/style-configuration')" [ngClass]="{'active': this.router.url.includes('/home/style-configuration')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-cog text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'STYLE_CONFIGURATION' | translate}} </span>
                    </a>
                </li>
				<-->
                <!-- <button class="btn btn-danger" tooltip="Logout" (click)="navLogin()" placement="bottom" style="display: flex; float: right; margin: 1%;">
                    <i class="fas fa-power-off"></i>
                </button> -->
				<!--
                <li  (click)="navigateTo('/home/style-configuration')" [ngClass]="{'active': this.router.url.includes('/home/style-configuration')}">
                    <a class="waves-effect waves-dark" aria-expanded="false">
                        <i class="fas fa-cog text-center"></i>
                        <span class="hide-menu ml-2" [ngStyle]="styling?.sidebarText">{{'STYLE_CONFIGURATION' | translate}} </span>
                    </a>
                </li>
				<-->
            </ul>
        </nav>
    </div>
	
    <div class="sidebar-footer hidden-sm-up text-center">
		<!--
        <a class="link" *ngIf="displayButton.styleConfView" (click)="navigateTo('/home/style-configuration')" data-toggle="tooltip" title="{{'STYLE_CONFIGURATION' | translate}}">
            <i class="fas fa-cog"></i>
        </a>
		<-->
        <!-- <a class="link" (click)="navigateTo('/under-construction')" data-toggle="tooltip" title="Notifications">
            <i class="fas fa-inbox"></i>
        </a> -->
        <!-- <a class="link" (click)="confirmModalPopup()" data-toggle="tooltip" title="Logout" style="width: 100%;">
            <i class="fas fa-power-off"></i>
        </a> -->
        <button class="btn btn-danger" (click)="confirmModalPopup()" placement="bottom" style="width: 90%; text-align: center;"
        [ngStyle]="styling?.button?.buttonPositive">
            {{'LOG_OUT' | translate}}
        </button>
    </div>
</aside>