import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { CommonApiService } from '../../shared/services/common-api.service';
import { DasboardService } from './dasboard.service';
import { UtilsService } from '../../shared/services/utils.service';
import { DatePipe } from '@angular/common';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
const permissionKeys = require("../../shared/services/permission-keys.json");

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CommonApiService, DasboardService],
})
export class DashboardComponent implements OnInit {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  policyNo = 0;
  billingInfoPerPolicy: any;
  billingInfo = [];
  paymentList: any;
  claimInfo = [];
  recentClaimInfo = [];
  policies:[];
  cardInfo: any;
  cardInfo2:any;
  agencyObject: any;
  policyNumber = this.utilsService.getPolicyNumber()
  styling = this.utilsService.getItemfromSession('themeObject');
  // policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary
  policyInfo: any
  customerName: any;
  // contactInfoFooter: any;
  displayButton : {
    policyView: any,
    billingView: any,
    claimView: any,
    allClaimView: any,
    recentClaimView: any
  }
 constructor(private http: CommonApiService, private dasboardService: DasboardService, 
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private privateDataShare: PrivateDataShare,
    private datepipe: DatePipe) { 
      this.policyInfo = this.privateDataShare.getPolicyDetails().PolicySummary
    }
  policyDocumentMetadata: any;
  ngOnInit(): void {
    this.displayButton = {
      policyView: this.keysAvailable.includes(permissionKeys.POLICY_VIEW),
      billingView: this.keysAvailable.includes(permissionKeys.BILLING_VIEW),
      claimView: this.keysAvailable.includes(permissionKeys.CLAIM_VIEW),
      allClaimView: this.keysAvailable.includes(permissionKeys.CLAIM_VIEW) && !this.keysAvailable.includes(permissionKeys.RECENT_CLAIM_VIEW),
      recentClaimView: this.keysAvailable.includes(permissionKeys.RECENT_CLAIM_VIEW)
    }
    this.getDetails();
    // this.getFooterContent();
  }
  // getFooterContent(){
      
  //   this.dasboardService.getCardInfo("contact_info_footer").subscribe(resp => {
  //     if(resp){
  //       this.contactInfoFooter = resp;
  //       this.contactInfoFooter = this.sanitizer.bypassSecurityTrustHtml(this.contactInfoFooter);
  //       }
  //   })
  // }

  getDetails() {
    this.dasboardService.getCustomerByID().subscribe(resp => {
      if(resp) this.customerName = resp.firstName;
    })
    this.dasboardService.getCardInfo("cardInfo").subscribe(resp => {
      if(resp){
        this.cardInfo = resp
        this.cardInfo = this.sanitizer.bypassSecurityTrustHtml(this.cardInfo);
      }
    })
    this.dasboardService.getCardInfo("cardInfo_2").subscribe(resp => {
      if(resp){
        this.cardInfo2 = resp
        this.cardInfo2 = this.sanitizer.bypassSecurityTrustHtml(this.cardInfo2);
      }
    })
    
    

    this.getClaims();
    this.getBillingInfo();
    }

    getBillingInfo(){ //needs improvement
      this.policies = this.policyInfo.map((resp) => resp.policyDetails.policyNumber);
      let i = 0;
      this.policies.forEach((policy, index, arr) => {
        this.dasboardService.getBillingInfo(policy).subscribe((resp)=> {
          const data = {
            policyNumber: policy,
            billingInfo: resp
          }
          this.billingInfo[index] = data
          i++;
          if(i == arr.length) this.billingInfo = [...this.billingInfo]
        });
      });
      
    }

  getClaims() { // needs improvement - not required for upc
    this.policies = this.policyInfo.map((resp) => resp.policyDetails.policyNumber);
    let claimIndex = 0;
    this.policies.forEach((policy, index, arr) => {
      this.dasboardService.getClaimsForPolicy(policy).subscribe((resp)=> {
        claimIndex++;
        let lastIndex = claimIndex == arr.length
        if(this.displayButton.recentClaimView)this.getRecentClaim(resp, index, lastIndex)
        var openClaims =0;
        if (resp != null) {
          var claimsList = resp.ClaimSummary
        
          for(var i=0; i<claimsList.length; i++){
            if(claimsList[i].claimStatus.toLowerCase().includes("open")){
              openClaims = openClaims + 1;
            }
          }
        }
        const data = {
          policyNumber: policy,
          openClaims: openClaims
        }
        this.claimInfo[index] = data
        if(lastIndex)this.claimInfo = [...this.claimInfo]
      });
    });
  }

  getRecentClaim(claimData, index, lastIndex){
    if(claimData && claimData.ClaimSummary){
      this.dasboardService.getAccountConfiguration(false).subscribe(resp => {
        if(resp.claim_configuration && 
          resp.claim_configuration.excluded_recent_claim_cause_of_loss &&
          resp.claim_configuration.excluded_recent_claim_status
          ){
            var excludeStatus = resp.claim_configuration.excluded_recent_claim_status;
            var excludedCauseOfLoss = resp.claim_configuration.excluded_recent_claim_cause_of_loss;
            }
      
      let claimsList: any;
      let recentClaim: any;
      claimsList = claimData.ClaimSummary.sort((a,b) => {return new Date(b.reportedDate).valueOf() - new Date(a.reportedDate).valueOf()})
      // var recentClaim = claimsList[0]; // if no excluded status or Cause of loss are configured
      for(var i=0; i<claimsList.length; i++){
        if( (!(excludeStatus.includes(claimsList[i].claimStatus))) && (!(excludedCauseOfLoss.includes(claimsList[i].causeOfLoss))) ){
          recentClaim = claimsList[i]
          break;
        }
      }
      if(recentClaim){
        const data = { recentClaim }
        if (data != null && data.recentClaim != null && data.recentClaim.dateOfLoss != null) {
          data.recentClaim.dateOfLoss =  this.datepipe.transform(data.recentClaim.dateOfLoss, 'MM/dd/yyyy');
        }
        this.recentClaimInfo[index] = data
        if(lastIndex) this.recentClaimInfo = [...this.recentClaimInfo]
      }

    })

    }
  }

  getPolicyIndex(index){
    console.log(this.policyInfo)
    this.policyNo = index
  }
}
