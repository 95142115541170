import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { Location } from '@angular/common';
import { UtilsService } from '../../shared/services/utils.service';
import { ValidationService } from '../../shared/services/validation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [DasboardService, CommonApiService]
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: any;
  isSubmitted = false;
  showPassword = true;
  passwordError = '';
  characterPassword = '~ ` ! @ # $ % ^ & * ( ) \/ < > " \' { } [ ] . _ + = ?';
  styling =this.utilsService.getItemfromSession('themeObject')
  
  constructor(
    private fb: FormBuilder,
    private dashboardService: DasboardService,
    private notifyToast: NotifytoastService,
    private location: Location,
    private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      NewPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20),
        // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]])[A-Za-z\\d~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]]+$'),
        ValidationService.passwordTenantValidator
      ]],
        confirmPassword: ['', Validators.required]
      })
      this.displayError()
  }

  saveNewPassword(){
    this.isSubmitted = true;
    const data = this.encryptPassword();
    if(this.changePasswordForm.valid){
      this.dashboardService.changePassword(data.data, this.changePasswordForm.value.oldPassword).subscribe(resp => {
        if (resp.out){
          this.notifyToast.success(' Password successfully updated.')
          this.navBack()
        } else{
          const errorText = `
          ${resp.root.Envelope.Body.Fault.faultstring}`
          this.utilsService.confirmModalPopup(errorText)
        }
      }, error => {
        this.notifyToast.error('Please contact System administrator')
      })
    }
  }

  encryptPassword(){
    let data = {...this.changePasswordForm.value}
    data.NewPassword = btoa(data.NewPassword)
    delete data.confirmPassword
    delete data.oldPassword
    return {data}
  }

  viewPassword(){
    this.showPassword = !this.showPassword;
  }

  inputChange(field) {
    // if (field === 'NewPassword' || field === 'confirmPassword' || field === 'oldPassword') {
      this.displayError()
      if (this.changePasswordForm.get('NewPassword').value !== this.changePasswordForm.get('confirmPassword').value) {
        this.changePasswordForm.get('confirmPassword').setErrors({ MatchPassword: true });
      }  else {
        this.changePasswordForm.get('confirmPassword').setErrors({ 'MatchPassword': null });
        this.changePasswordForm.get('confirmPassword').updateValueAndValidity()
      }

      if (this.changePasswordForm.get('NewPassword').value === this.changePasswordForm.get('oldPassword').value) { 
        this.changePasswordForm.get('oldPassword').setErrors({ SamePassword: true });
      } else{
        this.changePasswordForm.get('oldPassword').setErrors({ 'SamePassword': null });
        this.changePasswordForm.get('oldPassword').updateValueAndValidity()
      }
    // }
  }

  displayError() {
    const temp = this.changePasswordForm.get('NewPassword');
    if (temp.errors) {
      if (temp.errors.required) {
        this.passwordError =  'Account Code is required'
      } else if (temp.errors.invalidPassword) {
        this.passwordError =  'Password does not match the password requirements.'
      } else if (temp.errors.minlength) {
        this.passwordError =  'Account Code should be at least 8 characters'
      } else if (temp.errors.maxlength) {
        this.passwordError =  'Account Code should be no more than 20 characters'
      }
    }
  }

  navBack(){
    this.location.back()
  }

}
