<div class="card h-100">
    <div class="card-body body-mobile">
        <h2 class="custom-title text-center p-b-20 header-two-l" [ngStyle]="styling?.pageTitle">{{'ADD_POLICY' | translate}}</h2>
        <form [formGroup]="policyForm">
            <div class="row form-group">
                <div class="col-md-12">
                    <label class="custom-title-info" style="width: 30%;"  [ngStyle]="styling?.fieldPlaceHolder">
                        {{'POLICY_NUMBER' | translate}} * : 
                    </label>
                    <mat-form-field style="width: 50%">
                        <input matInput formControlName="policyNumber"  [ngStyle]="styling?.fieldValueInput" type="text" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" 
                    *ngIf="!policyForm.get('policyNumber').valid 
                    && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'Policy number'} }}</p>
                </div>

                <div class="col-md-12">
                    <label class="custom-title-info" style="width: 30%;" [ngStyle]="styling?.fieldPlaceHolder">
                        {{'POLICY_ADDRESS_ZIP_CODE' | translate}} * : 
                    </label>
                    <mat-form-field style="width: 50%">
                        <input matInput formControlName="zipCode" [ngStyle]="styling?.fieldValueInput" type="text" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" 
                    *ngIf="!policyForm.get('zipCode').valid 
                    && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'Policy Address Zip Code'} }}</p>
                </div>

                <div class="col-md-12">
                    <label class="custom-title-info" style="width: 30%;" [ngStyle]="styling?.fieldPlaceHolder" >
                        {{'PRIMARY_INSURED_DOB' | translate}} : 
                    </label>
    
                    <mat-form-field style="width: 8%;">
                        <!-- <input matInput formControlName="dateOfBirth" type="text" /> -->
                        <input matInput class="custom-title-infoDetail account-description" 
                        formControlName="dateOfBirth_month" type="text" [ngStyle]="styling?.fieldValueInput"
                        placeholder="MM" />
                    </mat-form-field>
                    /
                    <mat-form-field style="width: 8%;padding-left: 1%;">
                        <input matInput class="custom-title-infoDetail account-description" 
                        formControlName="dateOfBirth_date" type="text"  [ngStyle]="styling?.fieldValueInput"
                            placeholder="DD" />
                    </mat-form-field>
                    /
                    <mat-form-field style="width: 12%;padding-left: 1%;">
                        <input matInput class="custom-title-infoDetail account-description" 
                        formControlName="dateOfBirth_year" type="text"  [ngStyle]="styling?.fieldValueInput"
                            placeholder="YYYY" />
                    </mat-form-field> *
                    <p class="error-class mat-angular-error-text" 
                    *ngIf="(!policyForm.get('dateOfBirth_month').valid && isSubmitted)
                    || !policyForm.get('dateOfBirth_date').valid && isSubmitted
                    || !policyForm.get('dateOfBirth_year').valid && isSubmitted">
                    {{'REQUIRED_FIELD' | translate: {value:'Primary Insured\'s Date of Birth'} }}</p>
                </div>
            </div>
            <!-- <div class="row form-group">
                <div class="col-lg-12 text-right">
                    <button class="btn btn-danger buttons m-r-10" type="button" (click)="navToLink('home/my-account-view')"
                    [ngStyle]="styling?.button?.buttonBack">{{'CANCEL' | translate}}</button>
                    <button class="btn btn-info buttons custom-button-info" type="submit"  (click)="saveNewPolicyInfo()"
                    [ngStyle]="styling?.button?.buttonNext">{{'SUBMIT' | translate}}</button>
                </div>
            </div> -->
            <div class="col-md-8 offset-md-2" >
                <div class="row" style="margin: 40px 0 0 0; justify-content: center;">
                    <button class="btn btn-danger buttons col-lg-4" type="button" (click)="navToLink('home/my-account-view')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'CANCEL' | translate}}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button class="btn btn-info buttons custom-button-info col-lg-4" type="submit"  (click)="saveNewPolicyInfo()"
                    [ngStyle]="styling?.button?.buttonPositive">{{'SUBMIT' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>