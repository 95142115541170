import { Component, setTestabilityGetter, Inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { UtilsService } from './shared/services/utils.service';
import { has } from 'lodash';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AppService } from './app.service';
const permissionKeys = require("./shared/services/permission-keys.json")
declare var gPushEvent;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService]
})
export class AppComponent {
  public spinkit = Spinkit;
  title = 'Customer Portal';
  hasExternalScript: any;
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  browserSettings = this.utilsService.getItemfromSession('companybackground')
  constructor(
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private titleService: Title,
    private appService: AppService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.navigateHashRoute() //not necessary
    this.appService.googleAnalytics()
    // this.setGoogleAnalytics();
    // this.getsApps()
    this.setLanguage()
    const locationObj = window.location
    const location = locationObj.origin + '/' + this.utilsService.getTenantFromWindow() + '/'

    // if (location === environment.sfiUrl) {
    if (location.indexOf('/sfi') > -1) {
      require('style-loader!../assets/scss/sfi-style.scss');
    } else if (location === environment.upcUrl) {
      require('style-loader!../assets/scss/upc-style.scss');
    } else if (location.indexOf('/acme') > -1) {
      require('style-loader!../assets/scss/acme-style.scss');
    } else {
      require('style-loader!../assets/scss/default-style.scss');
    }
    // else {
    //   this.router.navigate(['sfi/new'])
    //   require('style-loader!../assets/scss/sfi-style.scss');
    // }
    // this.setTabTitle();  
    // this.setTabIcon(); 

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gPushEvent({ page_path: event.urlAfterRedirects, page_url: event.url, id: event.id, event: 'co.pageView' });
      }
    });
  }

  navigateHashRoute() {
    const locn = window.location;
    console.log("locn:", locn);

    let tenants = {
      "sfi": [
        "dev-my.securityfirstflorida.com",
        "https://insuredportal-test.hostedinsurance.com",
        "https://customer.usomc.org/",
        "http://customer.usomc.org/",
        "test-co-my.securityfirstflorida.com",
        "https://dev-my.securityfirstflorida.com/"
      ],
      "sfi-prod": [
        "my.securityfirstflorida.com",
        "https://insuredportal-prod.hostedinsurance.com/",
        "https://co-my.securityfirstflorida.com/",
        "https://my.securityfirstflorida.com/",
        // "localhost:4200"
      ]
    }

    Object.keys(tenants).some(resp => {
      if (tenants[resp].indexOf(locn.host) > -1 && !locn.href.includes(`/#/${resp}/`)) {
        if (locn.hash == "") {
          this.router.navigate([`/${resp}`]).then(() => window.location.reload())
        } else {
          var hashArray = locn.hash.split('/');
          hashArray = hashArray.filter(item => item !== '#');
          var skipLevels = '';
          for (let item in hashArray) {
            skipLevels = skipLevels + '../';
          }
          this.router.navigate([skipLevels, `${resp}`]).then(() => window.location.reload())
        }
      }
    })


    // if (locn.host == 'dev-my.securityfirstflorida.com' && !locn.href.includes('/#/sfi/')) {
    //   if (locn.hash == "") {
    //     this.router.navigate(['/sfi']).then(() => window.location.reload())
    //   } else {
    //     var hashArray = locn.hash.split('/');
    //     hashArray = hashArray.filter(item => item !== '#');
    //     var skipLevels = '';
    //     for (let item in hashArray) {
    //       skipLevels = skipLevels + '../';
    //     }
    //     this.router.navigate([skipLevels,'sfi']).then(() => window.location.reload())
    //   }
    // } else if (locn.host == 'my.securityfirstflorida.com' && !locn.href.includes('/#/sfi-prod/')) {
    //   if (locn.hash == "") {
    //     this.router.navigate(['/sfi-prod']).then(() => window.location.reload())
    //   } else {
    //     var hashArray = locn.hash.split('/');
    //     hashArray = hashArray.filter(item => item !== '#');
    //     var skipLevels = '';
    //     for (let item in hashArray) {
    //       skipLevels = skipLevels + '../';
    //     }
    //     this.router.navigate([skipLevels,'sfi-prod']).then(() => window.location.reload())
    //   }
    // }

    // if(locn.href == 'https://insuredportal-test.hostedinsurance.com/'){
    //   this.router.navigate(['/sfi']).then(() => window.location.reload())
    // } else if(locn.href == 'https://insuredportal-prod.hostedinsurance.com/'){
    //   this.router.navigate(['/sfi-prod']).then(() => window.location.reload())
    // } else if(locn.href == 'https://customer.usomc.org/'){
    //   this.router.navigate(['/sfi']).then(() => window.location.reload())
    // } else if(locn.href == 'http://customer.usomc.org/'){
    //   this.router.navigate(['/sfi']).then(() => window.location.reload())
    // } else if(locn.href == 'https://test-co-my.securityfirstflorida.com/'){
    //   this.router.navigate(['/sfi']).then(() => window.location.reload())
    // } else if(locn.href == 'https://co-my.securityfirstflorida.com/'){
    //   this.router.navigate(['/sfi-prod']).then(() => window.location.reload())
    // } else if(locn.href == 'https://my.securityfirstflorida.com/'){
    //   this.router.navigate(['/sfi-prod']).then(() => window.location.reload())
    // } else if(locn.href == 'https://dev-my.securityfirstflorida.com/'){
    //   this.router.navigate(['/sfi']).then(() => window.location.reload())
    // } 

    // else if(locn.href == 'https://dev-my.securityfirstflorida.com/?_ga=2.250237190.1617976326.1619804950-422899800.1538496121/'){
    //   this.router.navigate(['../sfi-prod']).then(() => window.location.reload())
    // } else if(locn.href == 'https://dev-my.securityfirstflorida.com/?_ga=2.250237190.1617976326.1619804950-422899800.1538496121'){
    //   this.router.navigate(['../sfi-prod']).then(() => window.location.reload())
    // } else if(locn.href == 'https://dev-my.securityfirstflorida.com/@/home/login/'){
    //   this.router.navigate(['../../../sfi-prod']).then(() => window.location.reload())
    // } 

  }

  setGoogleAnalytics() {
    let id = this.utilsService.getTagId()
    const script = document.createElement('script')
    script.async = true
    console.log(id);

    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
  }

  // setTabTitle() {
  //   if(this.browserSettings
  //     && this.browserSettings.browser
  //     && this.browserSettings.browser.browserTabTitle) {
  //       this.titleService.setTitle(this.browserSettings.browser.browserTabTitle); 
  //     } else {
  //       this.titleService.setTitle(this.title);
  //     }  
  // }

  // setTabIcon() {
  //   if (this.browserSettings
  //     && this.browserSettings.browser
  //     && this.browserSettings.browser.browserTabIcon ){
  //       this._document.getElementById('appFavicon').setAttribute('href', this.browserSettings.browser.browserTabIcon);
  //     }
  // }
  //  getApps(){
  //      const apps = navigator.getInstalledRelatedApps()
  //        apps.forEach(element => {
  //          console.log(element.id)
  //        });
  // }

  setLanguage() {
    let presetLang;
    const hasPermission = this.keysAvailable.includes(permissionKeys.LANGUAGE_SUPPORT);
    this.hasExternalScript = this.keysAvailable.includes(permissionKeys.EXTERNAL_WEBCHAT_SCRIPT);
    if (hasPermission) presetLang = sessionStorage.getItem('language') ? JSON.parse(sessionStorage.getItem('language')).langCode : 'en';
    else presetLang = 'en';
    this.translate.setDefaultLang('en');
    let tenant = this.utilsService.getTenantFromWindow()
    let lang = this.utilsService.selLangFile(tenant, presetLang)
    this.translate.use(lang);
  }
}