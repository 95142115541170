<div class="card h-100">
    <div class="card-body">
        <h2 class="custom-title header-two-l" [ngStyle]="styling?.pageTitle">{{'CHANGE_PASSWORD' | translate}}</h2>
        <form [formGroup]="changePasswordForm" autocomplete="false" (ngSubmit)="saveNewPassword()" class="form-horizontal form-material" id="changePasswordForm">
            <div class="form-group m-t-20">
                <div class="col-lg-12">
                    <mat-form-field style="width: 100%">
                        <input 
                        matInput 
                        formControlName="oldPassword" 
                        [type]="showPassword ? 'password': 'text'" 
                        (input)="inputChange('oldPassword')"
                        [ngStyle]="styling?.fieldValueInput"
                        placeholder="{{'OLD_PASSWORD' | translate}} *" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="changePasswordForm.get('oldPassword').errors?.required && isSubmitted" translate="REQUIRED_FIELD" [translateParams]="{value:'OLD_PASSWORD' | translate}"></p>
                    <p class="error-class mat-angular-error-text" *ngIf="changePasswordForm.controls.oldPassword.errors?.SamePassword && isSubmitted">Old password and new password are same</p>
                </div>
                <div class="col-lg-12">
                    <mat-form-field style="width: 100%">
                        <input 
                        matInput 
                        formControlName="NewPassword" 
                        [type]="showPassword ? 'password': 'text'" 
                        (input)="inputChange('NewPassword')"
                        [ngStyle]="styling?.fieldValueInput"
                        placeholder="{{'NEW_PASSWORD' | translate}} *" />
                         <span matSuffix>
                            <a class="fas fa-question-circle" [ngStyle]="styling?.toolTip" popoverTitle="Password Format" [popover]="passwordHint" container="body" triggers="mouseenter:mouseleave"></a>
                        </span>
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="!changePasswordForm.get('NewPassword').valid && isSubmitted">{{passwordError}}</p>
                </div>
                <div class="col-lg-12">
                    <mat-form-field style="width: 100%">
                        <input 
                        matInput 
                        formControlName="confirmPassword" 
                        [type]="showPassword ? 'password': 'text'" 
                        (input)="inputChange('confirmPassword')"
                        [ngStyle]="styling?.fieldValueInput"
                        placeholder="{{'CONFIRM_PASSWORD' | translate}} *" />
                         <span matSuffix>
                            <a class="fas fa-question-circle" [ngStyle]="styling?.toolTip" popoverTitle="Password Format" [popover]="passwordHint" container="body" triggers="mouseenter:mouseleave"></a>
                        </span>
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="changePasswordForm.controls.confirmPassword.errors?.required && isSubmitted">Re type password is required</p>
                    <p class="error-class mat-angular-error-text" *ngIf="changePasswordForm.controls.confirmPassword.errors?.MatchPassword && isSubmitted">The passwords you entered do not match. Please retry.</p>
                </div>
            </div>

            <div class="col-md-8 offset-md-2" >
                <div class="row" style="margin: 40px 0 0 0; justify-content: center;">
                    <span class="hyperlink-button col-md-5 condn-text-align m-auto hyperlinks" [ngStyle]="styling?.hyperlink" (click)="viewPassword()">
                        {{showPassword ? 'View': 'Hide'}} Password
                    </span>
                    <span class="m-r-20 m-b-20 hidden-md-up"></span>
                    <button type="button" (click)="navBack()" class="btn btn-danger col-md-3 buttons"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button type="submit" class="btn btn-info custom-button-info col-md-3 buttons"
                    [ngStyle]="styling?.button?.buttonPositive">{{'SAVE' | translate}}</button>
                </div>
            </div>
            <!-- <div class="row form-group">
                <div class="col-lg-12 text-right">
                    <span class="hyperlink-button m-r-10" (click)="viewPassword()">
                        {{showPassword ? 'View': 'Hide'}} Password
                    </span>
                    <button type="button" (click)="navBack()" class="btn btn-danger m-r-10"
                    [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                    <button type="submit" class="btn btn-info custom-button-info"
                    [ngStyle]="styling?.button?.buttonPositive">{{'SAVE' | translate}}</button>
                </div>
            </div> -->
        </form>
    </div>
</div>

<ng-template #passwordHint>
    <ul style="padding-left: 25px;">
        <li>You can use the following special characters in your password: <span [innerHTML]="characterPassword"></span></li>
        <li>Password must be at least 8 characters long, and have at least one letter and one number</li>
        <li>Passwords are case sensitive</li>
    </ul>
</ng-template>