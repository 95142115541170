import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { catchError, map } from 'rxjs/operators'
import { throwError as observableThrowError } from 'rxjs'
import { forkJoin, of } from 'rxjs'
import * as _ from 'lodash'
import { DasboardService } from '../dashboard/dasboard.service';
import { UtilsService } from '../../shared/services/utils.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { DatePipe } from '@angular/common';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from "../../shared/confirm-modal/confirm-modal.component";
import { TranslateService } from '@ngx-translate/core';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
import { Observable } from 'rxjs';
import { ShowUploadedFilesModalComponent } from './show-uploaded-files-modal/show-uploaded-files-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OpenCameraComponent } from './open-camera/open-camera.component';
const permissionKeys = require("../../shared/services/permission-keys.json");

@Component({
  selector: 'app-my-claim-list',
  templateUrl: './my-claim-list.component.html',
  styleUrls: ['./my-claim-list.component.scss'],
  providers: [DasboardService, CommonApiService]
})
export class MyClaimListComponent implements OnInit , OnChanges {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  claimForm: any;
  policyIndex = sessionStorage.getItem('CurPolicySelection');
  policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary;
  policyNumber:any;
  agencyObject ={}
  closedClaims = [];
  listAddress = [];
  addressObj = [];
  selectedAddressObj: any;
  openClaims = []
  claimsList:any;
  isLoading:any;
  currentBrowser: any;
  deviceInfo: any;
  displayButton: { newClaim: any }
  openClaimDataToTable: any;
  closeClaimDataToTable: any;
  tableActions: any[] = []
  customTableActions = {
    links: ['CLAIM_ID'],
    callback: [],
    actions: ['Upload'],
    alignment:{
      CLAIM_ID: 'text-center',
      DATE_OF_LOSS: 'text-center',
      CAUSE_OF_LOSS: 'text-center',
      EXAMINER_NAME: 'text-center',
      EXAMINER_PHONE: 'text-center',
      EXAMINER_EMAIL: 'text-center',
      LOSS_DESCRIPTION: 'text-center',
      LAST_PAYMENT_DATE: 'text-center',
      PAID_AMOUNT: 'text-center'
    }
  }

  styling = this.utilsService.getItemfromSession('themeObject')
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private translate: TranslateService,
    private privateDataShare: PrivateDataShare,
    private http: CommonApiService,
    private deviceService: DeviceDetectorService
    ) { }

  ngOnInit(): void {
    this.displayButton = {
      newClaim: this.keysAvailable.includes(permissionKeys.FILE_A_CLAIM)
    }
    this.initForm()
    this.currentBrowser = this.getCurrentBrowser();
    // this.getPolicyDetails()
    // this.getClaimInfo()
    // this.getClaims()
    if(this.keysAvailable.includes(permissionKeys.CLAIM_PICTURES_UPLOAD)) this.tableActions.push("Camera");
    if(this.keysAvailable.includes(permissionKeys.CLAIM_PICTURES_UPLOAD)) this.tableActions.push("Upload");
    if(this.keysAvailable.includes(permissionKeys.CLAIM_LIST_DOCUMENT)) this.tableActions.push("FileView");
    console.log(this.keysAvailable.includes(permissionKeys.CLAIM_LIST_DOCUMENT), 'test');
    
    this.customTableActions.actions = this.tableActions
    console.log(this.customTableActions, this.tableActions,'llol')
  }

  ngOnChanges(){
    console.log("changed: ",this.selectedAddressObj)
  }

  selectChange(eve){
    let address = eve.split(', ')

    for (let index in this.addressObj) {
      if(this.addressObj[index].policyDetails.riskAddress.addressLine1 === address[0] && this.addressObj[index].policyDetails.riskAddress.city === address[1] 
         && this.addressObj[index].policyDetails.riskAddress.stateCode +  ' ' + this.addressObj[index].policyDetails.riskAddress.zipCode === address[2]){
        sessionStorage.setItem('CurPolicySelection', index)
        this.selectedAddressObj = this.addressObj[index]
      
        this.openClaims = []
        this.closedClaims = []
        this.getClaims()
        this.getClaimInfo()
        return true
      }
    }
  }

  getCurrentBrowser() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // get current browser
    const browser = this.deviceInfo.browser.toLowerCase();
    console.log("find the browser: " + browser);
    switch (true) {
      case browser.indexOf('edge') > -1:
        return 'edge';
      case browser.indexOf('chrome') > -1:
        return'chrome';
      case browser.indexOf('trident') > -1:
        return 'ie';
      case browser.indexOf('firefox') > -1:
        return 'firefox';
      case browser.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  
  getClaims(){
    this.policyNumber = this.privateDataShare.getPolicyNumber();  
    this.dashboardService.getClaimsForPolicy(this.policyNumber).subscribe(resp => {
      if (resp != null) {
        this.claimsList = resp.ClaimSummary
      
        for(var i=0; i<this.claimsList.length; i++){
          if(this.claimsList[i].claimStatus.toLowerCase().includes("open")){
            this.openClaims.push(this.claimsList[i])
          }
          else if(this.claimsList[i].claimStatus.toLowerCase().includes("close")){
            this.closedClaims.push(this.claimsList[i])
          }
        }
        this.objectStructurize()
      } else {
        this.claimsList = null
        this.openClaimDataToTable = []
        this.closeClaimDataToTable = []
      }

      })
  }

  objectStructurize(){
    this.openClaimDataToTable = this.openClaims.map(resp =>{
      let temp = {
        CLAIM_ID: resp.claimNumber,
        DATE_OF_LOSS: this.datePipe.transform(resp.dateOfLoss, 'MM/dd/yyyy'),
        CAUSE_OF_LOSS: resp.causeOfLoss,
        EXAMINER_NAME: resp.assignedExaminerName,
        EXAMINER_PHONE: this.utilsService.formatNumber(resp.assignedExaminerPhone),
        EXAMINER_EMAIL: resp.assignedExaminerEmail,
        LOSS_DESCRIPTION: resp.lossDescription,
        LAST_PAYMENT_DATE: resp.lastIndemnityPaymentDate == null ? '' : this.datePipe.transform(resp.lastIndemnityPaymentDate, 'MM/dd/yyyy'),
        PAID_AMOUNT: '$ '+`${resp.totalIndemnityPaidToDate == null ? '0.00': this.utilsService.convertNumberToIS(resp.totalIndemnityPaidToDate)}`
      }
      return temp
    })
    
    this.closeClaimDataToTable = this.closedClaims.map(resp =>{
      let temp = {
        CLAIM_ID: resp.claimNumber,
        DATE_OF_LOSS: this.datePipe.transform(resp.dateOfLoss, 'MM/dd/yyyy'),
        CAUSE_OF_LOSS: resp.causeOfLoss,
        EXAMINER_NAME: resp.assignedExaminerName,
        EXAMINER_PHONE: this.utilsService.formatNumber(resp.assignedExaminerPhone),
        EXAMINER_EMAIL: resp.assignedExaminerEmail,
        LOSS_DESCRIPTION: resp.lossDescription,
        LAST_PAYMENT_DATE: resp.lastIndemnityPaymentDate == null ? '' : this.datePipe.transform(resp.lastIndemnityPaymentDate, 'MM/dd/yyyy'),
        PAID_AMOUNT: '$ '+`${resp.totalIndemnityPaidToDate == null ? '0.00': this.utilsService.convertNumberToIS(resp.totalIndemnityPaidToDate)}`
      }
      return temp
    })
  }

  getPolicyDetails(policyNo) {
    let policyDetailsObj = this.privateDataShare.getPolicyDetails()
    this.selectedAddressObj = policyDetailsObj.PolicySummary.find(resp => resp.policyDetails.policyNumber == policyNo).policyDetails
    // this.addressObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
    // this.selectedAddressObj = this.addressObj[this.policyIndex].policyDetails 
  }

  initForm(){
    this.claimForm = this.formBuilder.group({
      selectedAddress: []
    })
  }

  getClaimInfo(){ // needs improvement
    this.isLoading = true
    this.dashboardService.getCardInfo("claim_posting_info").subscribe(resp => {
      this.dashboardService.getCardInfo("needHelp_Claim").subscribe(needHelp => {
        const data = {
          claim_posting_info: resp,
          needHelp
        }
        this.agencyObject = data
      })
      this.isLoading = false
    })
  }

  navLocation(page){
    if(this.selectedAddressObj){
      const tenant = this.route.parent.snapshot.params.tenant
      this.router.navigate([`${tenant}/${page}`])
    } else{
      this.notifyToast.error("Please select an address")
    }
  }

  actionEventFromTable(dataObj: {actionType: any, rowData: any, file?: any}){
    if(dataObj.actionType == 'upload'){
      this.customTableActions.actions = ['Loading'] // display logo purpose
      this.uploadClaimDocument(dataObj)
    } else if(dataObj.actionType == 'FileView'){
      let claimID = dataObj.rowData.CLAIM_ID ? dataObj.rowData.CLAIM_ID : null
      // call API to list files ****
      this.dashboardService.getClaimDocumentsMetadata(claimID).subscribe(resp => {
        let initialState: ModalOptions = {
          backdrop: 'static',
          initialState: {
            fileList: resp && resp.DocumentMetadata,
            title: `Claim ${claimID} Files Uploaded`,
            closeBtnName: 'Close',
            currentBrowser: this.currentBrowser
          }
        } 
        this.modalService.show(ShowUploadedFilesModalComponent, initialState)
      })
    } else if(dataObj.actionType == 'Camera'){
      this.openCamera(dataObj)
    }
  }

  uploadClaimDocument(dataObj: {actionType: any, rowData: any, file?: any}){
    this.uploadDocs(dataObj.file, dataObj.rowData).subscribe(resp => {
      // this.customTableActions.actions = ['Upload', 'FileView', 'Camera']
      this.customTableActions.actions = this.tableActions
      console.log(resp, 'here')
      if(resp.length){
        let claimNo = resp[0].claimNo
        let successDocs = _.filter(resp, {'uploaded': true})
        let failDocs = _.filter(resp, {'uploaded': false, 'sizeLimitExceeded': false})
        let fileSizeExceededDocs = _.filter(resp, {'uploaded': false, 'sizeLimitExceeded': true})
        if(resp.length == successDocs.length){
          this.successModal(successDocs, claimNo)
        } else{
          let fileUploadSize = this.privateDataShare.getFileUploadSize() 
          let textDisplay = ''
          this.translate.get(['CLAIM_UPLOAD_ERROR','CLAIM_UPLOAD_SUCCESS_AND_ERROR',
          'CLAIM_UPLOAD_REMINDER'], {claimNo: claimNo, fileSize: fileUploadSize}).subscribe(respText =>{
            console.log(respText)
            if(successDocs.length){
              textDisplay += `<p> Files uploaded Successfully  </p>`
              successDocs.forEach((claim) => {
                textDisplay += `<li> ${claim.fileName}  </li>`
              });
              textDisplay += '<br/>'
            }
            if(failDocs.length){
              let text = resp.length == failDocs.length ? respText['CLAIM_UPLOAD_ERROR']: respText['CLAIM_UPLOAD_SUCCESS_AND_ERROR']
              textDisplay += `<p> ${text}  </p>`
              failDocs.forEach((claim) => {
                textDisplay += `<li> ${claim.fileName}  </li>`
              });
              textDisplay += '<br/>'
            } if(fileSizeExceededDocs.length){
              textDisplay += `<p> ${respText['CLAIM_UPLOAD_REMINDER']}  </p>`
              fileSizeExceededDocs.forEach((claim) => {
                textDisplay += `<li> ${claim.fileName}  </li>`
              });
            }
            if(textDisplay)this.utilsService.confirmModalPopupWithConfig('Upload Error', textDisplay, 'Ok')
          })
        }
      }
    })
  }

  openCamera(dataObj){

    let initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-lg',
      initialState: {
        title: 'Take Picture/Video',
        file: dataObj.file,
        claimRowData: dataObj.rowData,
        tenant: this.route.parent.snapshot.params.tenant,
        currentBrowser: this.currentBrowser
      }
    } 
    this.modalService.show(OpenCameraComponent, initialState);
  }

  successModal(claimData, claimNo){
    setTimeout(() => {
      let textDisplay = '';
      this.translate.get('CLAIM_UPLOAD_SUCCESS', {claimNo: claimNo }).subscribe(text =>{
        if(claimData.length) {
          textDisplay += `<p> ${text}  </p>`
          claimData.forEach((claim) => {
            textDisplay += `<li> ${claim.fileName}  </li>`
          });
        } 
      })
      const modal = this.modalService.show(ConfirmModalComponent, {backdrop: 'static'});
      (<ConfirmModalComponent>modal.content).showConfirmationModalOneSelection('Upload Successful', textDisplay, 'Ok');
    }, 500);
  }

  uploadDocs(files, claimRow): Observable<any>{
    let fileUploadSize = this.privateDataShare.getFileUploadSize() 
    fileUploadSize = fileUploadSize ? parseInt(fileUploadSize)*1024*1024 : 5242880
    let observables = files.map((file, index) => {
      if(file.size < fileUploadSize){
        let convertedData = this.convertFormData(file, claimRow)
        return this.http.privateUpload('uploadclaimsdocuments', convertedData, true).pipe(
          map((resp) => {
            if(resp.statusCode == 200){
                let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, true, false)
                return tempData
              } else{
                let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, false, false)
                return tempData
              }
          }),
          catchError(error => this.errorCase(file.name, claimRow.CLAIM_ID))
        )
      } else{
        let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, false, true)
        return of(tempData)
      }
    })
    return forkJoin(observables)
  }

  errorCase(fileName, claimNo){
    let tempData = this.getPicRespData(fileName, claimNo, false, false)
    return observableThrowError(tempData)
  }

  getPicRespData(fileName, claimNo, uploaded, sizeLimitExceeded?){
    let tempData = {fileName, claimNo, uploaded, sizeLimitExceeded}
    return tempData
  }

  // uploadDocs1(files, claimRow){
  //   let fileUploadSize = this.privateDataShare.getFileUploadSize() 
  //   fileUploadSize = fileUploadSize ? parseInt(fileUploadSize)*1024*1024 : 5242880
  //   let uploadedDocs = []
  //   let errorDocs = []
  //   let fileSizeExceeded = []
    
  //   var check = new Promise((resolve, reject) => {
  //     files.forEach((file, index, array) => {
  //       if(file.size < fileUploadSize){

  //         let convertedData = this.convertFormData(file, claimRow)
    
  //           this.dashboardService.uploadClaim(convertedData).subscribe(resp => {
  //             // this.notifyToast.success(`${file.name} uploaded successfully`) //tested only for pdfs
  //             if(resp.statusCode == 200){
  //               uploadedDocs.push(file.name)
  //               if(uploadedDocs.length == array.length) {
  //                 resolve({uploadedDocs, claimNo: claimRow.CLAIM_ID})
  //                 this.customTableActions.actions = ['Upload'] // display logo purpose
  //               } else if(errorDocs.length > 0 && array.length == errorDocs.length + uploadedDocs.length){
  //                 reject({uploadedDocs, errorDocs, claimNo: claimRow.CLAIM_ID})
  //               } 
  //             } else{
  //               this.customTableActions.actions = ['Upload'] // display logo purpose
  //               reject({msg:'Please contact System Administrator'})
  //             }
  //           }, error => {
  //             errorDocs.push(file.name)
  //             this.customTableActions.actions = ['Upload']
  //             let msg = error.message ? error.message : ''
  //             // this.notifyToast.error(`Please contact System Administrator ${msg}`)
  //             if(array.length == errorDocs.length + uploadedDocs.length){
  //               reject({msg, uploadedDocs, errorDocs, claimNo: claimRow.CLAIM_ID})
  //             } 
  //             // else reject({msg})
  //           })
  //       } else{
  //         reject({msg:''})
  //         this.customTableActions.actions = ['Upload']
  //         fileSizeExceeded.push(file.name)
  //       }
  //     });
  //     if(fileSizeExceeded.length) {
  //       const fileSizeInBytes = fileUploadSize/(1024*1024)
  //       this.notifyToast.error(`${fileSizeExceeded.join(", ")} has not been uploaded as file size exceeds ${fileSizeInBytes} MB.`)
  //     }
  //   })

    
  //   check.then(resp => {
  //     this.displayConfirmationModal(resp)
  //   })
  //   check.catch(resp => {
  //     let successDocs = uploadedDocs
  //     let failDocs = errorDocs
  //     let textDisplay = ''
  //     this.translate.get('CLAIM_UPLOAD_ERROR', {failedDocs: failDocs, claimNo: claimRow.CLAIM_ID}).subscribe(text =>{
  //       if(errorDocs.length && successDocs.length === 0){
  //         textDisplay += `<p> ${text} </p>`
  //         for(var i=0; i<errorDocs.length; i++) {
  //           textDisplay += `<li> ${errorDocs[i]}  </li>`
  //         }
  //         textDisplay += `<p></p>`
  //         console.log("test body: " + textDisplay)
  //       }
  //     })
  //     this.translate.get('CLAIM_UPLOAD_SUCCESS_AND_ERROR', {failedDocs: failDocs, claimNo: claimRow.CLAIM_ID}).subscribe(text =>{
  //       if(errorDocs.length && successDocs.length){
  //         textDisplay += `<p> ${text}  </p>`
  //         for(var i=0; i<errorDocs.length; i++) {
  //           textDisplay += `<li> ${errorDocs[i]}  </li>`
  //         }
  //         textDisplay += `<p></p>`
  //         console.log("test body: " + textDisplay)
  //       }
  //     })
  //     this.translate.get('CLAIM_UPLOAD_REMINDER', {failedDocs: failDocs, claimNo: claimRow.CLAIM_ID}).subscribe(text =>{
  //       if(errorDocs.length) {
  //         textDisplay += `<p> ${text}  </p>`
  //       }
  //     })
  //     if(textDisplay)this.utilsService.confirmModalPopupWithConfig('Upload Error', textDisplay, 'Ok')
  //   }) 
    
  // }

  // displayConfirmationModal(claimData){
  //   // console.log(claimData.uploadedDocs)
  //   setTimeout(() => {
  //     let filesName = claimData.uploadedDocs;
  //     let textDisplay = '';
  //     this.translate.get('CLAIM_UPLOAD_SUCCESS', {uploadedDocs: filesName, claimNo: claimData.claimNo  }).subscribe(text =>{
  //       if(claimData.uploadedDocs.length) {
  //         textDisplay += `<p> ${text}  </p>`
  //         for(var i=0; i<filesName.length; i++) {
  //           textDisplay += `<li> ${filesName[i]}  </li>`
  //         }
  //       } 
  //     })
  //     this.utilsService.confirmModalPopupWithConfig('Upload Successful', textDisplay, 'Ok')

  //     // console.log(filesName, claimData.claimNo)
  //     // const modal = this.modalService.show(ConfirmModalComponent, {backdrop: 'static'});
  //     //   (<ConfirmModalComponent>modal.content).showConfirmationModalOneSelection(
  //     //     '<b>Upload Claim Documents</b>', 
  //     //     `${filesName} has been successfully added to your claim # ${claimData.claimNo}`, 
  //     //     `Ok`);
  //     //   (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {})
      
  //   }, 500);
  // }

  convertFormData(file, claimRow){
    const formData = new FormData()
    const tenant = this.route.parent.snapshot.params.tenant
    formData.append('claimID', claimRow['CLAIM_ID'])
    formData.append('accountCode', tenant)
    formData.append('file', file)
    return formData
  }

  getPolicyIndex(policyNo){
    // console.log('reached heree')
    // this.policyIndex = index;
    this.getPolicyDetails(policyNo);
    this.openClaims = []
    this.closedClaims = []
    this.getClaims()
    this.getClaimInfo()  
  }

}
