import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { UtilsService } from '../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
const permissionKeys = require('../../shared/services/permission-keys.json');

@Component({
  selector: 'app-my-billing-view',
  templateUrl: './my-billing-view.component.html',
  styleUrls: ['./my-billing-view.component.scss'],
  providers: [CommonApiService, DasboardService],
  encapsulation: ViewEncapsulation.None,
})
export class MyBillingViewComponent implements OnInit {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys')
    ? this.utilsService.getItemfromSession('permissionKeys')
    : [];
  policyStatus = this.utilsService.getPolicyStatus();
  policyNumber = this.utilsService.getPolicyNumber();
  statusTip: any;
  feesTip: any;
  tenant: any;
  paymentList: any;
  renewalTermPaymentDetails: any;
  currentTermPaymentDetails: any;
  termsLength: any;
  paymentPlan: any;
  termStartDate: any;
  termEndDate: any;
  billTo: any;
  nextPaymentDue: any;
  expandRow: any;
  cancelledPolicyMessage: any;
  dueDate: any;
  totalPremium: any;
  termType = 'Current';
  policyIndex = sessionStorage.getItem('CurPolicySelection')
  termChange = false;
  remainingBalance: any;
  billingInfo: any;
  policiesForm: any;
  hasBalanceDue: any;
  paymentMethodsList: ['Credit Card', 'Bank Account'];
  listAddress = [];
  addressObj = [];
  policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary;
  selectedAddressObj = [];
  styling = this.utilsService.getItemfromSession('themeObject');
  largeWindow: any;
  displayButton: { makePayment: any };
  paymentDataToTable: any;
  customTableActions = {
    links: [],
    callback: [],
    tooltip: {
      STATUS: {
        title: 'Status Info',
        description: '',
      },
      FEES: {
        title: 'Fee Info',
        description: '',
      },
    },
    alignment: {
      DUE_DATE: 'text-center',
      STATUS: 'text-center',
      PREMIUM: 'text-right',
      FEES: 'text-right',
      INSTALLMENT_AMOUNT: 'text-right',
      INSTALLMENT_DUE: 'text-right',
    },
  };
  constructor(
    public utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private route: ActivatedRoute,
    private router: Router,
    private http: CommonApiService,
    private privateDataShare: PrivateDataShare,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.largeWindow = window.innerWidth >= 992;
    this.tenant = this.route.parent.snapshot.params.tenant;
    this.displayButton = {
      makePayment: this.keysAvailable.includes(permissionKeys.MAKE_PAYMENT),
    };
    this.initForm();
    this.termType = 'Current';
    this.getInstallmentDetails();
    this.getBillingDetails();
    // this.getPolicyDetails();
    this.getToolTips();
    if (this.policyStatus == "Cancelled") {
      this.getCancelledPolicyMessage()
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeWindow = window.innerWidth >= 992;
  }
  getToolTips() {
    var infoType = 'payments_tool_tips';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.customTableActions.tooltip.STATUS.description =
              resp.out.status_tip;
            this.customTableActions.tooltip.FEES.description =
              resp.out.fees_tip;
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getPolicyDetails() {
    this.addressObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
    this.selectedAddressObj = this.addressObj[this.policyIndex].policyDetails
  }

  initForm() {
    this.policiesForm = this.formBuilder.group({
      selectedAddress: [],
      billingAddress: [],
    });
  }
  navigateToLink(page) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${page}`]);
  }
  navigateToPayment(page) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${page}`]);
  }
  getBillingDetails() {
    this.dashboardService.getPolicyInfoByPolicyNumber().subscribe((resp) => {
      var billingAddress =
        resp.billingDetails.billingAddress.addressLine1 +
        ', ' +
        resp.billingDetails.billingAddress.city +
        ', ' +
        resp.billingDetails.billingAddress.stateCode +
        ' ' +
        resp.billingDetails.billingAddress.zipCode;
      this.policiesForm.patchValue({
        billingAddress: billingAddress,
      });
    });
  }

  getCancelledPolicyMessage() {

    this.dashboardService.getCardInfo("cancelled_policy_payment_message").subscribe(resp => {
      if (resp) {
        this.cancelledPolicyMessage = resp;
      }
    })
  }
  objectStructurize() {
    this.paymentDataToTable = this.paymentList.map((resp) => {
      let temp = {
        DUE_DATE: this.datepipe.transform(resp.dueDate, 'MM/dd/yyyy'),
        STATUS: resp.status,
        PREMIUM: '$' + this.utilsService.convertNumberToIS(resp.premium),
        FEES: '$' + this.utilsService.convertNumberToIS(resp.fees),
        INSTALLMENT_AMOUNT:
          '$' + this.utilsService.convertNumberToIS(resp.dueAmount),
        INSTALLMENT_DUE:
          '$' + this.utilsService.convertNumberToIS(resp.balanceDue),
      };
      return temp;
    });
  }

  getInstallmentDetails() {
    this.dashboardService.getPaymentSummary().subscribe((resp) => {
      this.termsLength = resp.length;
      for (var paymentInfo of resp) {
        if (paymentInfo.termType == 'Current') {
          this.currentTermPaymentDetails = paymentInfo;
        } else if (paymentInfo.termType == 'Future') {
          this.renewalTermPaymentDetails = paymentInfo;
        }
      }
      this.termType = ((!this.currentTermPaymentDetails) && (this.renewalTermPaymentDetails)) ? "Future" : "Current";
      this.getPaymentSummary();
    });
  }
  getPaymentSummary() {
    var paymentList = [];
    this.paymentList = []
    if (this.termType == 'Current') {
      paymentList = this.currentTermPaymentDetails.installments.Installment;
      this.termStartDate = this.currentTermPaymentDetails.effectiveDate;
      this.termEndDate = this.currentTermPaymentDetails.expirationDate;
      this.billTo = this.currentTermPaymentDetails.billTo;
      this.paymentPlan = this.currentTermPaymentDetails.paymentPlan;
    } else if (this.termType == 'Future') {
      paymentList = this.renewalTermPaymentDetails.installments.Installment;
      this.termStartDate = this.renewalTermPaymentDetails.effectiveDate;
      this.termEndDate = this.renewalTermPaymentDetails.expirationDate;
      this.billTo = this.renewalTermPaymentDetails.billTo;
      this.paymentPlan = this.renewalTermPaymentDetails.paymentPlan;
    }
    this.totalPremium = '0.00';
    this.remainingBalance = '0.00';
    this.nextPaymentDue = '0.00';
    this.dueDate = 'No Payment Due';
    for (var i = 0, j = 0; i < paymentList.length; i++) {
      paymentList[i]['status'] = (paymentList[i]['status'] == 'Open') ? 'Billed' : paymentList[i]['status']
      if (paymentList[i]['status'] != 'Failed') {
        paymentList[i]['fees'] = (
          parseFloat(paymentList[i]['installmentFee']) +
          parseFloat(paymentList[i]['lateFee']) +
          parseFloat(paymentList[i]['penaltyFee']) +
          parseFloat(paymentList[i]['policyFee']) +
          parseFloat(paymentList[i]['premiumTax'])
        ).toFixed(2);
        paymentList[i]['premium'] = (
          parseFloat(paymentList[i]['dueAmount']) -
          parseFloat(paymentList[i]['fees'])
        ).toFixed(2);

        if (j == 0 && paymentList[i]['balanceDue'] != '0.00') {
          this.expandRow = i
          this.nextPaymentDue = paymentList[i]['balanceDue'];
          this.dueDate = paymentList[i]['dueDate'];
          j++;
        }
        this.totalPremium = (
          parseFloat(this.totalPremium) +
          parseFloat(paymentList[i]['dueAmount'])
        ).toFixed(2);
        this.remainingBalance = (
          parseFloat(this.remainingBalance) +
          parseFloat(paymentList[i]['balanceDue'])
        ).toFixed(2);
        this.paymentList.push(paymentList[i]);
        if (
          this.termType == 'Current' &&
          this.remainingBalance == 0.0 &&
          this.termsLength > 1 &&
          !this.termChange
        ) {
          this.termChange = false;
          this.changeTerm();
        }
      }
    }
    this.hasBalanceDue = !(this.nextPaymentDue === '0.00');
    this.objectStructurize();
  }

  changeTerm() {
    this.termType == 'Current'
      ? (this.termType = 'Future')
      : (this.termType = 'Current');
    this.termChange = true;
    this.getPaymentSummary();
  }
  getPolicyIndex(policyNo) {
    let policyDetails = []
    policyDetails = this.privateDataShare.getPolicyDetails().PolicySummary
    // this.policyIndex = index;
    this.policyNumber = policyNo;
    // this.policyStatus = this.utilsService.getPolicyStatus();
    this.policyStatus = policyDetails.find(resp => resp.policyDetails.policyNumber == policyNo).policyDetails.status
    this.termType = 'Current';
    this.getInstallmentDetails();
    this.getBillingDetails();
    // this.getPolicyDetails()
  }
}
