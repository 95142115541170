import { format } from 'path';
import { Component, OnInit, HostListener } from '@angular/core';
import { ValidationService } from '../../shared/services/validation.service';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { DatePipe, formatNumber } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { UtilsService } from '../../shared/services/utils.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { UserWarningComponent } from '../../shared/user-warning/user-warning.component';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
const permissionKeys = require("../../shared/services/permission-keys.json");


@Component({
  selector: 'app-my-claim-new',
  templateUrl: './my-claim-new.component.html',
  styleUrls: ['./my-claim-new.component.scss'],
  providers: [ DasboardService, CommonApiService, DatePipe, BsModalService ]
})
export class MyClaimNewComponent implements OnInit {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : [];
  policyIndex = sessionStorage.getItem('CurPolicySelection');
  policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary;
  isEditable = false;
  errorText: any;
  knownError: any;
  agencyObject = {};
  isSubmitted = false;
  resultSumittedClaim: any;
  datePickerSettings = {
    isAnimated: true,
    adaptivePosition: true,
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false,
  };
  severity:any;
  newClaimForm: any;
  emailError:any;
  emergencySelection = false;
  floodToolTip:any;
  claimConfirmation:any;
  isFirstPartyLossType = false;
  isFloodRelated = false;
  catastrophiesList = [];
  tempCatList = [];
  causeOfLossList = [];
  emergency_services_tool_tips:any
  emergencyList = [];
  styling = this.utilsService.getItemfromSession('themeObject');
  catastrophyAddress: any;
  policyNo: any;
  maxDate = new Date();
  isSmallWindow: boolean;
  additional_features = {
    rooms_damaged: false,
    roof_damage : false,
    moved_out : false,
    water_enter : false,
    col_water_entry_dependency : false,
    is_home_livable : true
  }
  notes:any;
  mainContactList = [];
  isOtherContact = false;
  roomsDamageList = [
    'No damage',
    '1-2 rooms',
    '3 rooms',
    '4+ rooms',
    'Total Loss of Home Structure',
  ];
  confirmationModalContent: any;
  selectedAddressObj: any;
  isNotClickable = false;
  hideInputFields = false;
  contactPhoneNo = ''

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private router: Router,
    private dashboardService: DasboardService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe,
    private privateDataShare: PrivateDataShare
  ) {
    this.policyNo = this.privateDataShare.getPolicyNumber();
    this.getUpdatedAddress(this.policyNo)
    // this.catastrophyAddress =
    //   this.policyInfo[this.policyIndex].policyDetails.riskAddress;
  }

  ngOnInit(): void {
    sessionStorage.setItem('showClaimUserWarning', 'true')
    this.isSmallWindow = window.innerWidth <= 768;
    
    this.initForm();
    this.getClaimInfo();
    this.getConfiguration();
    this.getCausesOfLoss();
    if (!this.catastrophyAddress) {
      this.navLocation('home/my-claims/list');
    }
    this.initialContactName();
    this.getToolTips();
    this.getClaimConfirmation();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 768;
    console.log('small window: ' + this.isSmallWindow);
  }

  getUpdatedAddress(policyNo){
    let policyDetailsObj = this.privateDataShare.getPolicyDetails()
    this.selectedAddressObj = policyDetailsObj.PolicySummary.find(resp => resp.policyDetails.policyNumber == policyNo).policyDetails
    this.catastrophyAddress = this.selectedAddressObj.riskAddress
  }

  initialContactName() {
    // var name = this.utilsService.getItemfromSession('policyDetails').PolicySummary[this.policyIndex].policyDetails.primaryInsured.fullName
    // var email = this.utilsService.getItemfromSession('policyDetails').PolicySummary[this.policyIndex].policyDetails.primaryInsured.email
    // var phonenumbers = this.utilsService.getItemfromSession('policyDetails').PolicySummary[this.policyIndex].policyDetails.primaryInsured.phoneNumbers.PhoneNumber

    var name = this.selectedAddressObj.primaryInsured.fullName
    var email = this.selectedAddressObj.primaryInsured.email
    var phonenumbers = this.selectedAddressObj.primaryInsured.phoneNumbers.PhoneNumber
    //finding the primary phone number
    this.contactPhoneNo = this.utilsService.formatNumber(phonenumbers.find(e => e.primary == true).number)
    //defaulting the name, email and phone number to PNI
    this.newClaimForm.get('contactClaim').patchValue(name);
    this.newClaimForm.get('email').patchValue(email);
    this.newClaimForm.get('ph_number').patchValue(this.contactPhoneNo);
    
    }
  initForm() {
    this.newClaimForm = this.formBuilder.group({
      lossDescription: ['', Validators.required],
      lostDate: [new Date(), Validators.required],
      catastrophy: [{name:'No'},Validators.required],
      lostTime: [null, Validators.required],
      causeOfLoss: [null, Validators.required],
      emergencyServices: new FormGroup({}),
      contactClaim: ['', Validators.required],
      email: [null, [Validators.required, ValidationService.emailValidator]],
      ph_number: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
          Validators.maxLength(14),
        ],
      ],
      countryCode: [
        '1',
        [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(3),
        ],
      ],
      mainContact: [null, Validators.required],
      relationshipToPrimaryholder: [''],
      numberOfDamagedRooms: [null],
      roofDamage: [null],
      movedOut: [null],
      waterEntryDueToFlood: [null],
    });
  }

  phoneNumberChange(eve) {
    if (eve.target.value === 1) eve.preventDefault();
  }

  getClaimInfo() {
    // needs improvement
    this.dashboardService
      .getCardInfo('claim_posting_info')
      .subscribe((resp) => {
        this.dashboardService
          .getCardInfo('needHelp_Claim')
          .subscribe((needHelp) => {
            const data = {
              claim_posting_info: resp,
              needHelp,
            };
            this.agencyObject = data;
          });
      });
  }

  getCausesOfLoss() {
    this.dashboardService.getCausesOfLoss().subscribe((resp) => {
      this.causeOfLossList = resp;
      this.tempCatList = resp;
    });
  }

  causeOfLossChange(eve) {
    //check for permissions
    if(this.keysAvailable.includes(permissionKeys.CUSTOM_FILE_A_CLAIM)){
    if(this.additional_features.water_enter  && eve.isFloodRelated == "TRUE"){
      this.isFloodRelated = true;
      this.newClaimForm.get('waterEntryDueToFlood').setValidators([Validators.required]);
      this.newClaimForm.get('waterEntryDueToFlood').updateValueAndValidity();
    }
    else{
      this.isFloodRelated = false;
      this.newClaimForm.get('waterEntryDueToFlood').setValue(null);
      this.newClaimForm.get('waterEntryDueToFlood').setValidators();
      this.newClaimForm.get('waterEntryDueToFlood').updateValueAndValidity();
    }
    if (eve.lossPartyType == 'First Party') {
      this.isFirstPartyLossType = true;
      if(this.additional_features.rooms_damaged){
      this.newClaimForm
        .get('numberOfDamagedRooms')
        .setValidators([Validators.required]);
      this.newClaimForm.get('numberOfDamagedRooms').updateValueAndValidity();
    }
    if(this.additional_features.roof_damage){
      this.newClaimForm.get('roofDamage').setValidators([Validators.required]);
      this.newClaimForm.get('roofDamage').updateValueAndValidity();
    }
    if(this.additional_features.moved_out){
      this.newClaimForm.get('movedOut').setValidators([Validators.required]);
      this.newClaimForm.get('movedOut').updateValueAndValidity();
    }
    } else{
      this.isFirstPartyLossType = false;
      this.newClaimForm.get('numberOfDamagedRooms').setValue(null);
      this.newClaimForm.get('numberOfDamagedRooms').setValidators();
      this.newClaimForm.get('numberOfDamagedRooms').updateValueAndValidity();
      this.newClaimForm.get('roofDamage').setValue(null);
      this.newClaimForm.get('roofDamage').setValidators();
      this.newClaimForm.get('roofDamage').updateValueAndValidity();
      this.newClaimForm.get('movedOut').setValue(null);
      this.newClaimForm.get('movedOut').setValidators();
      this.newClaimForm.get('movedOut').updateValueAndValidity();
    }
  }
  }

  dateChange(eve) {
    setTimeout(() => {
      if(eve){
        if(eve != this.newClaimForm.value.lostDate) this.newClaimForm.patchValue({lostDate: eve})
        this.dashboardService
          .getCatastrophes(
            this.catastrophyAddress,
            eve
          )
          .subscribe((resp) => {
            if (resp) {
              this.catastrophiesList = resp.Catastrophe
              // let cataId =
              //   this.newClaimForm.value.catastrophy &&
              //   this.newClaimForm.value.catastrophy.id;
                // if (!this.catastrophiesList.some((resp) => resp.id === cataId)) {
                if(resp.Catastrophe){
                this.nullifyCatastrophy(resp.Catastrophe);
              }
              }
            else{
              this.nullifyCatastrophy();
  
            } 
            });
          }
    }, 500);
  }

  updateRelationship(eve) {
    if(eve.displayName === 'Primary Policyholder'){
      this.hideInputFields = true
      this.initialContactName()
    }
    else{
      this.hideInputFields = false
      this.newClaimForm.get('email').patchValue(null);
    }
    if (eve.code === 'Other') {
      this.isOtherContact = true;
      this.newClaimForm
        .get('relationshipToPrimaryholder')
        .setValidators([Validators.required]);
      this.newClaimForm
        .get('relationshipToPrimaryholder')
        .updateValueAndValidity();
    } else {
      this.isOtherContact = false;
      this.newClaimForm.get('relationshipToPrimaryholder').setValidators();
      this.newClaimForm
        .get('relationshipToPrimaryholder')
        .updateValueAndValidity();
    }
  }
  nullifyCatastrophy(hasCatastrophy?) {
    this.newClaimForm.patchValue({ catastrophy: {name:'No'} });
    this.catastrophiesList = hasCatastrophy ? [{name:'No'}].concat(hasCatastrophy):[{name:'No'}];
    this.selectedCatastrophy()
    }

  getConfiguration() {
    this.dashboardService.getAccountConfiguration().subscribe((resp) => {
      if (resp.claim_configuration) {
        this.emergencyList = resp.claim_configuration.Emergency_Service_List;
        const formArray = this.newClaimForm.get('emergencyServices');
        this.emergencyList.forEach((data, i) => {
          formArray.addControl(data, new FormControl(false));
        });
        //checking if the tenant has custom file a claim and then checking for additional fields
        if(this.keysAvailable.includes(permissionKeys.CUSTOM_FILE_A_CLAIM)){
          this.additional_features = {
            rooms_damaged: resp.claim_configuration.additional_features.includes('rooms_damaged'),
            roof_damage : resp.claim_configuration.additional_features.includes('roof_damage'),
            moved_out : resp.claim_configuration.additional_features.includes('moved_out'),
            water_enter : resp.claim_configuration.additional_features.includes('water_enter'),
            col_water_entry_dependency : resp.claim_configuration.additional_features.includes('col_water_entry_dependency'),
            is_home_livable : resp.claim_configuration.additional_features.includes('is_home_livable')
          }
          if(resp.claim_configuration.additional_features.includes('col_water_entry_dependency'))
          this.notes = resp.content_management.claim_notes.col_water_entry_dependency
        } 
        this.mainContactList = resp.claim_configuration.main_contact_list;
        this.newClaimForm.patchValue({
          mainContact: this.mainContactList[0],
        });
        if(this.mainContactList[0].displayName === 'Primary Policyholder'){
          this.hideInputFields = true
        } else {
          this.hideInputFields = false
        }
        //rooms list
      }
      if (resp.content_management && resp.content_management.showConfirmationModalBodyInBelowConfiguration) {
        this.confirmationModalContent = resp.content_management.showConfirmationModalBodyInBelowConfiguration;
      }
    });
  }

  selectedCatastrophy() {
    this.isFloodRelated = null;
    this.isFirstPartyLossType = null;
    this.newClaimForm.patchValue({
      causeOfLoss: null,
    });
    const selectedLoss = this.newClaimForm.value.catastrophy;
    if( selectedLoss.name == 'No'){
      this.causeOfLossList = this.tempCatList
    } else if (selectedLoss) {
      this.causeOfLossList = selectedLoss.causesOfLoss.Peril;

    } else {
      this.causeOfLossList = [];
    }
  }

  goBack(stepper: MatStepper) {
    this.isNotClickable = false
    stepper.previous();
  }
  checkIfAnyEmergencyIsSelected(){
    for(var emergencyService in this.newClaimForm.value.emergencyServices){
      if(this.newClaimForm.value.emergencyServices[emergencyService]){
        return true
      }
    }
    return false
  }
  goForward(stepper: MatStepper) {
    this.emergencySelection=this.checkIfAnyEmergencyIsSelected();
    this.isSubmitted = true
    if(stepper.selectedIndex === 0 && this.newClaimForm.valid){
      const modal = this.modalService.show(ConfirmModalComponent, {backdrop: 'static'});
      (<ConfirmModalComponent>modal.content).showConfirmationModalBodyInBelow(this.confirmationModalContent.Title, this.confirmationModalContent.body,this.confirmationModalContent.rejectBtnTxt, this.confirmationModalContent.acceptBtnTxt);
      (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
        if(resp === true){
          this.submitForm(stepper)
          sessionStorage.setItem('showClaimUserWarning', 'false')
        }
      })
    } 
    
    if(stepper.selectedIndex === 1){
      this.navLocation('home/my-claims/list')
    }
  }
  submitForm(stepper: MatStepper) {
    let data = { ...this.newClaimForm.value };
    this.calculateSeverity().subscribe((resp) => {
      this.severity = resp.out.severity;
      var COL = data.causeOfLoss.code
      var is_home_livable
      var noteSubject;
      var noteDescription;
      if(this.keysAvailable.includes(permissionKeys.CUSTOM_FILE_A_CLAIM) && this.additional_features.col_water_entry_dependency){
        //if a non-cat claim with a flood related cause of loss and water entered due to outside flooding or sewage backup, selecting COL as "Flood" - SFI specific(col_water_entry_dependency) logic
        if (((data.catastrophy.name == 'No') && data.waterEntryDueToFlood)) {
          COL = 'FF';
          noteSubject = this.notes.subject;
          noteDescription = this.notes.description.replace('[Selected COL]',data.causeOfLoss.displayName);
        } else {
          COL = data.causeOfLoss.code
        }
      } else {
        COL = data.causeOfLoss.code
      }

      if(this.keysAvailable.includes(permissionKeys.CUSTOM_FILE_A_CLAIM) && this.additional_features.is_home_livable){
        is_home_livable = ((data.numberOfDamagedRooms == 'Total Loss of Home Structure') || data.movedOut == "true")? false : true
      } 
      const submitData = {
        policyNumber: this.policyNo,
        
        causeOfLossCode: COL,
        dateOfLoss: data.lostDate.toLocaleDateString('en-US'),
        isCatastrophy: !(data.catastrophy.name == 'No'),
        catastropheId: 
          data.catastrophy && !(data.catastrophy.name == 'No') ? data.catastrophy.id : null,
        lossDescription: data.lossDescription,
        timeOfLoss: data.lostTime
          ? this.datePipe.transform(data.lostTime, 'h:mm a')
          : null,
        contactPhone: data.ph_number.replace(/\D/g, ''),
        emergencyServices: data.emergencyServices,
        reportedBy: data.mainContact ? data.mainContact.code : 'Insured',
        severity: this.severity,
        email: data.email,
        partyType : data.mainContact.partyType,
        reportedDate: new Date(),
        fullName : data.contactClaim,
        isHomeLiveable : is_home_livable,
        noteDescription: noteDescription,
        noteSubject: noteSubject
      };
      if (this.newClaimForm.valid) {
        this.dashboardService.createClaim(submitData).subscribe((resp) => {
          if (resp.statusCode !== undefined) {
            this.isNotClickable = false
            this.isEditable = true;
            this.errorText = `${resp.errorMessage}`;
            this.knownError = resp.statusCode == '901' ? false : true;
          } else {
            this.isNotClickable = true
            this.isEditable = false;
            this.notifyToast.success('Claim has been successfully created.');
            this.resultSumittedClaim = resp.out;
          }
          stepper.next();
        });
      }
    });
  
  }

  calculateSeverity() {
    const tenant = this.route.parent.snapshot.params.tenant;
    const roofDamage =
      this.newClaimForm.value.roofDamage == 'not sure'
        ? false
        : this.newClaimForm.value.roofDamage;
    const numberOfDamagedRooms = this.newClaimForm.value.numberOfDamagedRooms;
    const movedOut = this.newClaimForm.value.movedOut;
    return this.http.privateGet(
      `getSeverity?accountCode=${tenant}&roofDamage=${roofDamage}&numberOfDamagedRooms=${numberOfDamagedRooms}&movedOut=${movedOut}`,
      null,
      true
    );
  }

  cancelClaim(page) {
    var infoType = 'user_warning_cancelled_claim';
    const tenant = this.route.parent.snapshot.params.tenant;
    this.http
      .get(`getCardInfo?accountCode=${tenant}&infoType=${infoType}`, null, true)
      .subscribe((resp) => {
        if (resp) {
          var warningMessage = resp.out;
          const modal = this.modalService.show(UserWarningComponent, {
            backdrop: 'static',
          });
          (<UserWarningComponent>modal.content).showUserWarning(
            tenant,
            warningMessage,
            page
          );
        }
      });
  }

  getClaimConfirmation(){
    var infoType = 'claim_confirmation';
    const tenant = this.route.parent.snapshot.params.tenant;
    this.http
      .get(
        `getCardInfo?accountCode=${tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.claimConfirmation=resp.out
            }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  
  getToolTips() {
    var infoType = 'claims_tool_tips';
    const tenant = this.route.parent.snapshot.params.tenant;
    this.http
      .get(
        `getCardInfo?accountCode=${tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          this.floodToolTip={}
          if (resp) {
            this.floodToolTip.content =
              resp.out.flood_tip.content;
              this.floodToolTip.header =
              resp.out.flood_tip.header;
              this.emergency_services_tool_tips = resp.out.emergency_services_tool_tips;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  navLocation(page) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${page}`]);
  }

  keyEmail(){
    const temp = this.newClaimForm.get('email')
    if(temp.errors){
      if (temp.errors.invalidEmailAddress && !temp.errors.required) {
        this.emailError =  'Email is not valid.'
      } else{
        this.emailError = ''
      }
    }
  }

  getPolicyIndex(policyNo){
    // this.policyIndex = index;
    this.privateDataShare.setPolicyNumber(policyNo)
    sessionStorage.setItem('showClaimUserWarning', 'true')
    this.policyNo = policyNo;
    this.getUpdatedAddress(policyNo)
    // this.catastrophyAddress =
    //   this.policyInfo[this.policyIndex].policyDetails.riskAddress;
    // this.initForm();
    this.initialContactName();
  }
}
