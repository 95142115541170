<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">   
        <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.cardTitle">
                {{'MY_BILLING' | translate}}
            </h4>

            <button class="btn btn-info custom-button-info mini-button buttons" [ngClass]="{
                'disableCursor': ((billingObj?.billingInfo?.dueDate == 'No Payment Due') ||(billingObj == undefined))
                }"
            [disabled]="billingObj?.billingInfo?.dueDate == 'No Payment Due' || billingObj == undefined"
            (click)="navToLink('home/my-billing-view/payment')"
            [ngStyle]="styling?.button?.buttonNavigation">{{'PAY_NOW' | translate}}</button>
        </div>
        <div class="row" style="padding-top:20px;" *ngIf="billingDetails">
            
            <div class="col-md-12" >
                <p class="form-control-static custom-description p-b-5">
                    <span [ngStyle]="styling?.attributeKey" class="account-description">
                        {{'BILL_PLAN' | translate}}: </span>
                    <span [ngStyle]="styling?.attributeValue" class="account-title">
                        {{billingObj?.billingInfo?.paymentPlan}}
                    </span>
                </p>
                <p class="form-control-static custom-description p-b-5">
                    <span [ngStyle]="styling?.attributeKey"class="account-description">
                        {{'DUE_DATE' | translate}}: </span>
                    <span [ngStyle]="styling?.attributeValue" class="account-title">
                        {{billingObj?.billingInfo?.dueDate}}
                    </span>
                </p>
                <p class="form-control-static custom-description p-b-5">
                    <span [ngStyle]="styling?.attributeKey" class="account-description">
                        {{'NEXT_PAYMENT_DUE' | translate}}: </span>
                    <span [ngStyle]="styling?.attributeValue" class="account-title">
                        ${{utilsService.convertNumberToIS(billingObj?.billingInfo?.nextPaymentDue)}}
                    </span>
                </p>
            </div>
            <div class="col-md-12">
                <div class="custom-link account-title text-center hyperlinks"
                    (click) ="navToLink('home/my-billing-view')"
                    [ngStyle]="styling?.hyperlink">
                    {{'VIEW_BILLING' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>