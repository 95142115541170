import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { DasboardService } from '../../dashboard/dasboard.service';
const permissionKeys = require("../../../shared/services/permission-keys.json");

@Component({
  selector: 'app-policy-details-view',
  templateUrl: './policy-details-view.component.html',
  styleUrls: ['./policy-details-view.component.scss']
})
export class PolicyDetailsViewComponent implements OnInit, OnChanges {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  @Input() selectedAddressObj: any;
  styling =this.utilsService.getItemfromSession('themeObject')
  mortgagee = "N/A";
  displayButton : { policyCoverages: any, policyDocuments: any}
  policyType: any;
  billingAddress: any;
  policyTypeConfiguration: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private dashboardService: DasboardService,
  ) { }

  ngOnInit(): void {
    this.displayButton = {
      policyCoverages: this.keysAvailable.includes(permissionKeys.POLICY_COVERAGES),
      policyDocuments: this.keysAvailable.includes(permissionKeys.POLICY_DOCUMENTS)
    }
    this.getConfiguration();
    // this.setPolicyType();
    
  }

  setPolicyType() {
    var policyForm = this.selectedAddressObj && this.selectedAddressObj.policyForm;
    if (policyForm && this.policyTypeConfiguration
      && this.policyTypeConfiguration[policyForm] != null 
      && this.policyTypeConfiguration[policyForm] != undefined) {
        this.policyType = this.policyTypeConfiguration[policyForm];
      } else {
        this.policyType = "N/A"
        console.log("Error: can not get the policy type.")
      } 
  }
  
  getConfiguration() {
    this.dashboardService.getAccountConfiguration().subscribe((resp) => {
      if (resp.content_management && resp.content_management.policyType) {
        this.policyTypeConfiguration = resp.content_management.policyType;
      } else {
        console.log("ERROR: can not get policy type configuration.")
      }
    });
  }



  getBillingDetails() {
    this.dashboardService.getPolicyInfoByPolicyNumber().subscribe((resp) => {
      this.setPolicyType();
      this.billingAddress =
        resp.billingDetails.billingAddress.addressLine1 +
        ', ' +
        resp.billingDetails.billingAddress.city +
        ', ' +
        resp.billingDetails.billingAddress.stateCode +
        ' ' +
        resp.billingDetails.billingAddress.zipCode;
    });
  }

  ngOnChanges(){
    this.getBillingDetails();
    // this.setPolicyType();
    if('interestedParties' in this.selectedAddressObj){
        this.mortgagee = this.selectedAddressObj.interestedParties.InterestedParty[0].company;
        console.log("mortgagee:", this.mortgagee)
      }
      else{
        this.mortgagee = "N/A"
      }
  }

  checkObject(obj){
    return Object.keys(obj).length
  }

  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

}
