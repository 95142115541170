import { Injectable } from '@angular/core';
import { CanActivate, Router,  } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class AuthGuardMaintenance implements CanActivate {

  constructor(
    private authService: AuthService, private router: Router,private utilsService: UtilsService, private datepipe: DatePipe
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.inMaintenance().pipe(map((resp: string) => {
        let tenant = sessionStorage.getItem("maintenanceModeTenant")
        if (resp === "maintenance") {
           // this.router.navigate(['./maintenance']);
            
            this.router.navigate([`${tenant}/maintenance`]);
            return false;
          } else if (resp === "reminder"){

            const reminderData = sessionStorage.getItem('maintenanceMode')? JSON.parse(sessionStorage.getItem('maintenanceMode')) : null;
            if (reminderData != null 
                && reminderData.alertInfo != null 
                && reminderData.alertInfo.alertMessage != null
                && reminderData.alertInfo.title != null
                && reminderData.alertInfo.buttonContent != null) {
                  let alertMessage = reminderData.alertInfo.alertMessage;
                  if (reminderData.alertInfo.insertMaintenanceTime 
                    && reminderData.alertInfo.alertMessageInsertMaintenanceTime != null
                    && reminderData.alertInfo.alertMessageInsertMaintenanceTime.insertAfter != null
                    && reminderData.alertInfo.alertMessageInsertMaintenanceTime.insertBefore != null) {
                      let time = new Date(reminderData.UTCTime);
                      let date = this.datepipe.transform(time, 'MM/dd/yyyy');
                      let hourAndMinute = this.datepipe.transform(time, 'shortTime');
                    alertMessage = reminderData.alertInfo.alertMessageInsertMaintenanceTime.insertBefore 
                    + ' at ' + hourAndMinute + ' on ' + date + reminderData.alertInfo.alertMessageInsertMaintenanceTime.insertAfter;
                  } 
                  //console.log("alert message: " + alertMessage)
                    this.utilsService.confirmModalPopupWithConfig(reminderData.alertInfo.title,alertMessage,reminderData.alertInfo.buttonContent);
            } else {
                this.utilsService.confirmModalPopupWithConfig("Maintenance Reminder","This site will be under maintenance","OK");
                //alert('This site will be under maintenance');
            }
            return true;
         } else {
            //console.log("auth guard not maintenance")
            //this.router.navigate([`${tenant}`]);
            return true;
        }
    }))
  }

}