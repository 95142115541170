import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import {MatStepperModule} from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs';
import {MatCurrencyFormatModule} from 'mat-currency-format';
import {MatSliderModule} from '@angular/material/slider';
import { AgmCoreModule } from "@agm/core";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PublicComponent } from './public.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { DemoGetAQuoteComponent } from './demo-get-aquote/demo-get-aquote.component';
import { MatCardModule } from '@angular/material/card';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { MessageCardComponent } from './get-quote/message-card/message-card.component';
import { PersonalInfoFormComponent } from './get-quote/personal-info-form/personal-info-form.component';
import { IsRentFormComponent } from './get-quote/is-rent-form/is-rent-form.component';
import { IsHouseFormComponent } from './get-quote/is-house-form/is-house-form.component';
import { IsResidenceFormComponent } from './get-quote/is-residence-form/is-residence-form.component';
import { SelectRoofFormComponent } from './get-quote/select-roof-form/select-roof-form.component';
import { SelectPremiumComponent } from './get-quote/select-premium/select-premium.component';
import { CalculatingProgressComponent } from './get-quote/calculating-progress/calculating-progress.component';
import { ShowPremiumComponent } from './get-quote/show-premium/show-premium.component';
import { CoverageDetailsComponent } from './get-quote/coverage-details/coverage-details.component';
import { AdditionalRoofInfoFormComponent } from './get-quote/additional-roof-info-form/additional-roof-info-form.component';
import { HomeAgeSizeComponent } from './get-quote/home-age-size/home-age-size.component';
import { AdditionalHomeDetailsComponent } from './get-quote/additional-home-details/additional-home-details.component';
import { FindAgentsInGmapComponent } from './get-quote/find-agents-in-gmap/find-agents-in-gmap.component';
import { EmailQuoteComponent } from './get-quote/email-quote/email-quote.component';
import { SendQuoteAgentComponent } from './get-quote/send-quote-agent/send-quote-agent.component';
import { EmailAgentConfirmationComponent } from './get-quote/email-agent-confirmation/email-agent-confirmation.component';
import { MaintenanceViewComponent } from './maintenance-view/maintenance-view.component';
import { CommonApiService } from '../shared/services/common-api.service';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    PublicComponent, 
    LoginComponent, 
    UnderConstructionComponent, 
    ForgotPasswordComponent, 
    ForgotUsernameComponent, 
    SignUpComponent, 
    GetAQuoteComponent, 
    DemoGetAQuoteComponent, 
    GetQuoteComponent, 
    MessageCardComponent, 
    PersonalInfoFormComponent, 
    IsRentFormComponent, 
    IsHouseFormComponent, 
    IsResidenceFormComponent, 
    SelectRoofFormComponent, 
    SelectPremiumComponent, 
    CalculatingProgressComponent, 
    ShowPremiumComponent, 
    CoverageDetailsComponent,
    AdditionalRoofInfoFormComponent,
    HomeAgeSizeComponent,
    AdditionalHomeDetailsComponent,
    FindAgentsInGmapComponent,
    SendQuoteAgentComponent,
    EmailQuoteComponent,
    EmailAgentConfirmationComponent,
    MaintenanceViewComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PublicRoutingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormsModule,
    BsDatepickerModule,
    MatInputModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatCurrencyFormatModule,
    MatStepperModule,
    SharedModule,
    NgSelectModule,
    MatStepperModule,
    MatCardModule,
    MatSliderModule,
    AgmCoreModule,
    CountdownModule 
  ],
  exports: [GetAQuoteComponent],
  providers: [ CommonApiService],

})
export class PublicModule { }
