<div class="card custom-card-border h-100">
    <div class="card-body body-mobile">

        <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            My Billing
        </h2>
        <div class="row">
            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>
        <div class="row">
            
            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'TOTAL_PREMIUM' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">${{utilsService.convertNumberToIS(totalPremium)}}</span>
                </p>
            </div>
            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'REMAINING_BALANCE' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">${{utilsService.convertNumberToIS(remainingBalance)}}</span>
                </p>
            </div>

            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'NEXT_PAYMENT_DUE' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">${{utilsService.convertNumberToIS(nextPaymentDue)}}</span>
                </p>
            </div>
            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">Due Date:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">{{ (dueDate == 'No Payment Due')? dueDate :(dueDate | date: 'MM/dd/yyyy')}}</span>
                </p>
            </div>

            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'BILLING_ADDRESS' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">{{this.policiesForm.value.billingAddress}}</span>
                </p>
            </div>

            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'BILL_TO' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">{{billTo}}</span>
                </p>
            </div>

            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'POLICY_TERM' | translate}}:
                    <span class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">
                        {{termStartDate | date: 'MM/dd/yyyy'}} - {{termEndDate | date: 'MM/dd/yyyy'}}</span>
                </p>
            </div>

            <div class="col-md-6">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    {{'BILL_PLAN' | translate}}:
                    <span class="custom-title-infoDetail account-description"
                        [ngStyle]="styling?.attributeValue">{{paymentPlan}}</span>
                </p>
            </div>

            <div class="col-md-6" *ngIf="termsLength > 1">
                <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
                    <span class="custom-link custom-title-infoDetail account-description hyperlinks"
                        [ngStyle]="styling?.hyperlink"
                        (click)="changeTerm()">{{(this.termType=='Current')?'View Renewal Term Details':'View Current Term Details'}}</span>
                </p>
            </div>
        </div>


        <div [innerHTML]="cancelledPolicyMessage  | safeHtml" *ngIf="policyStatus == 'Cancelled'"></div>

        <div style="padding: 40px 20px 20px 20px;" class="text-center"
            *ngIf="displayButton.makePayment && policyStatus == 'Active'">
            <button class="col-md-4 btn btn-info custom-button-info buttons"
                [ngClass]="{'disableCursor': (!this.hasBalanceDue)}" [ngStyle]="styling?.button?.buttonNavigation"
                [disabled]="!this.hasBalanceDue" (click)="navigateToPayment('home/my-billing-view/payment')">Make a
                Payment</button>
        </div>


        <hr style="height:5px;border-width:0;color:gray;background-color:gray">

        <div class="text-center">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.pageSubTitle"
                style="padding-top:25px;">Payment Schedule</h4>

            <app-custom-table [tableDataObj]="paymentDataToTable" [customTableActions]="customTableActions"
                [openAccordion]="expandRow">
            </app-custom-table>
        </div>


    </div>

</div>