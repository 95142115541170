import { throwError as observableThrowError, Observable } from 'rxjs'
import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators'
import { SpinnerVisibilityService } from 'ng-http-loader';

import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
import { NotifytoastService } from './notificationToast.service';

// @Injectable()
export class HttpService {
  private tenant;
  private actionBaseUrl;
  private http;
  private loader;
  private router;
  private baseURL;
  private notifyToast;
  constructor(http: HttpClient, loader: SpinnerVisibilityService,@Inject('') baseURL: String, router: Router, notifyToast: NotifytoastService) {
    this.http = http;
    this.loader = loader;
    this.baseURL = baseURL;
    this.router = router;
    this.notifyToast = notifyToast;
    this.tenant = window.location.hash.split('/')[1]
  }

  getBaseUrl(url) {
    // return this.actionBaseUrl + url;
    return this.baseURL + url;
  }

  public get(url: string, param: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // AccessKey: 'AKIARAZ4XIJUQVEW6XHS',
      // SecretKey: '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'
    })
    const options = {
      headers,
      params: param
    }
    return this.http.get(this.getBaseUrl(url), options).pipe(
      map((data: any) => {
        this.responseInterceptor()
        return data.data || data;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public put(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      // AccessKey: 'AKIARAZ4XIJUQVEW6XHS',
      // SecretKey: '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',
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public post(url: string, data: any, showLoader: boolean, noNeedAuth?: boolean): Observable<any> {
    if(showLoader) this.requestInterceptor()
    let headers;
    let dataObj;
    if(!noNeedAuth){
      const username = data.username;
      const password = data.password;
      dataObj = null;
      headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Basic " + btoa(`${username}:${password}`)
      })
    } else{
      headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
      dataObj = data
    }
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), dataObj, options).pipe(
      map((resp: any) => {
        if(showLoader) this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateGet(url: string, param: any, showLoader: boolean): Observable<any> {
    if(showLoader) {
      this.requestInterceptor()
    }
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers,
      params: param
    }
    return this.http.get(this.getBaseUrl(url), options).pipe(
      map((data: any) => {
        if(showLoader)this.responseInterceptor()
        if(data && data.response) return this.checkTokenExpiry(data)
        else return data.data || data;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privatePut(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()    
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        if(data && data.response) this.checkTokenExpiry(data)
        else return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privatePost(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        if(data && data.response) this.checkTokenExpiry(data)
        else return (resp && resp.data) ? resp.data : resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  
  public changePasswordPut(url: string, data: any, oldPwd: string, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const username = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).username : null
    
    const headers = new HttpHeaders({
      Accept: 'application/json',
        'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(username+":"+oldPwd)
    })
    const options = {
      headers
    }
    return this.http.put(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateDelete(url: string, data: any, showLoader: boolean): Observable<any> {
    this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      Accept: 'q=0.8;application/json;q=0.9',
      'Content-Type': 'application/json',
      Authorization: token
    })
    const options = {
      headers
    }
    return this.http.delete(this.getBaseUrl(url), options).pipe(
      map((resp: any) => {
        this.responseInterceptor()
        return resp.data || resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  public privateUpload(url: string, data: any, showLoader: boolean): Observable<any> {
    // this.requestInterceptor()
    const token = sessionStorage.getItem('user') ? 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).idToken.jwtToken : null
    const headers = new HttpHeaders({
      
      'enctype': 'multipart/form-data',
      'Accept': 'application/json',
      Authorization: "Basic " + btoa(this.getUsernameToken())
    })
    const options = {
      headers
    }
    return this.http.post(this.getBaseUrl(url), data, options).pipe(
      map((resp: any) => {
        // this.responseInterceptor()
        if(data && data.response) this.checkTokenExpiry(data)
        else return (resp && resp.data) ? resp.data : resp;
      }
      ),
      catchError(error => this.handleError(error))
    )
  }

  private handleError(error: any) {
    this.responseInterceptor()
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error'
    if (error.status === 401) {
      sessionStorage.clear()
      this.router.navigate([`${this.tenant}/login`]).then(() => {window.location.reload()})
      this.notifyToast.error('Your Session is Expired.')
      return observableThrowError(errMsg)
    } else {
      // return observableThrowError(errMsg)
      return observableThrowError(error)
    }
  }

  private requestInterceptor(): void {	  
    this.loader.show();
  }

  private responseInterceptor(): void {
    /*
	setTimeout(() => {
      
      this.loader.hide();
    }, 700);
*/	
    this.loader.hide();
  }

  getUsernameToken(){
    const tokenDetails = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null
    return `${tokenDetails.username}:${tokenDetails.token}`
  }

  checkTokenExpiry(data){
    console.log('Expiry')
    if(data.root.Envelope.Body.Fault.faultstring == "Authentication token is expired."){
      this.notifyToast.error("Authentication token is expired.")
      sessionStorage.clear();
      this.router.navigate([`${this.tenant}/login`])
      // .then(() => {window.location.reload()})
      console.log('sjowS')
      return null
    } else{
      return data.data || data
    }
  }

}
