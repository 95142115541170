import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs';
import {MatCurrencyFormatModule} from 'mat-currency-format';
import { AgmCoreModule } from "@agm/core";

import { PrivateComponent } from './private.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivateRoutingModule } from './private-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PublicModule } from '../public/public.module';

import { PoliciesCardComponent } from './dashboard/policies-card/policies-card.component';
import { BillingCardComponent } from './dashboard/billing-card/billing-card.component';
import { ClaimCardComponent } from './dashboard/claim-card/claim-card.component';
import { UtilsService } from '../shared/services/utils.service';
import { InfoCardComponent } from './dashboard/info-card/info-card.component';
import { ViewPoliciesComponent } from './view-policies/view-policies.component';
import { PolicyDetailsViewComponent } from './view-policies/policy-details-view/policy-details-view.component';
import { ViewPolicyCoveragesComponent } from './view-policies/view-policy-coverages/view-policy-coverages.component';
import { MyAccountViewComponent } from './my-account-view/my-account-view.component';
import { MyAccountEditComponent } from './my-account-edit/my-account-edit.component';
import { AgentinfoCardComponent } from './dashboard/agentinfo-card/agentinfo-card.component';
import { StylingConfigurationComponent } from './styling-configuration/styling-configuration.component';
import { InlinePageStylingComponent } from './styling-configuration/inline-page-styling/inline-page-styling.component';
import { ViewPolicyDocumentsComponent } from './view-policies/view-policy-documents/view-policy-documents.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyBillingViewComponent } from './my-billing-view/my-billing-view.component';
import { LargeAgentInfoComponent } from './large-agent-info/large-agent-info.component';
import { DataTransferService } from '../shared/services/dataTransfer.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { TimepickerModule } from 'ngx-bootstrap/timepicker'
import { CarouselModule, CarouselConfig } from 'ngx-bootstrap/carousel'
import { PaymentComponent } from './my-billing-view/payment/payment.component';
import { MyClaimListComponent } from './my-claim-list/my-claim-list.component';
import { MyClaimNewComponent } from './my-claim-new/my-claim-new.component';
import { AddPolicyComponent } from './add-policy/add-policy.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { DatePipe } from '@angular/common';
import { FindAgentsInMapComponent } from './find-agents-in-map/find-agents-in-map.component';
import { RecentClaimCardComponent } from './dashboard/recent-claim-card/recent-claim-card.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PrivateResolver } from './private.resolver';
import { ShowUploadedFilesModalComponent } from './my-claim-list/show-uploaded-files-modal/show-uploaded-files-modal.component';
import { OpenCameraComponent } from './my-claim-list/open-camera/open-camera.component';
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    PrivateComponent, 
    DashboardComponent, 
    PoliciesCardComponent, 
    BillingCardComponent, 
    ClaimCardComponent, 
    InfoCardComponent, 
    ViewPoliciesComponent, 
    PolicyDetailsViewComponent, 
    ViewPolicyCoveragesComponent, 
    MyAccountViewComponent, 
    MyAccountEditComponent, 
    AgentinfoCardComponent, 
    StylingConfigurationComponent, 
    InlinePageStylingComponent, 
    ViewPolicyDocumentsComponent, 
    ChangePasswordComponent, 
    MyBillingViewComponent, 
    LargeAgentInfoComponent,
    PaymentComponent, 
    MyClaimListComponent, 
    MyClaimNewComponent, 
    AddPolicyComponent, 
    GetQuoteComponent, 
    FindAgentsInMapComponent, RecentClaimCardComponent, ShowUploadedFilesModalComponent, OpenCameraComponent
    ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    SharedModule,
    PublicModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatCurrencyFormatModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CarouselModule.forRoot(),
    PopoverModule.forRoot(),
    MatStepperModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    TooltipModule,
    MatTooltipModule,
    AgmCoreModule,
    // GoogleTagManagerModule.forRoot({
    //   id: environment.gtmID.sfiGTM
    // })
  ],
  providers: 
  [
    UtilsService,
    DataTransferService,
    DatePipe,
    PrivateResolver,
    {provide: CarouselConfig, useValue: {interval: 5000, showIndicators: true} 
  }]
})
export class PrivateModule { }
