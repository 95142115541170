<div class="card h-100">
    <div class="card-body body-mobile">
      <div class="row">
        <div class="col-lg-4 text-right m-b-10" *ngIf="isSmallWindow">
          <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
          (click)="navToLink('home/view-policies')"
          [ngStyle]="styling?.button?.buttonNegative">
          {{'BACK_TO_POLICY' | translate}}</button>
        </div>
        <div class="col-md-8">
          <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
            {{'POLICY_DOCUMENT_FOR' | translate}}: 
            <span class="custom-title-infoDetail account-description" 
            [ngStyle]="styling?.attributeValue">{{this.selectedAddressObj}}</span>
          </p>
        </div>
        <div class="col-lg-4 text-right" *ngIf="!isSmallWindow">
          <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
          (click)="navToLink('home/view-policies')"
          [ngStyle]="styling?.button?.buttonNegative">
          {{'BACK_TO_POLICY' | translate}}</button>
        </div>
        <div class="col-md-12">
          <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
            {{'POLICY_NUMBER' | translate}}: 
            <span class="custom-title-infoDetail account-description" 
            [ngStyle]="styling?.attributeValue">{{policyNumber}}</span>
          </p>
        </div>
      </div>
        

      <div class="p-t-20">
          <div class="table-responsive">
              <table class="table table-striped">
                <thead style="background-color: #A9A9A9;">
                  <tr>
                    <th [ngStyle]="styling?.tableHeader">Coverages</th>
                    <th [ngStyle]="styling?.tableHeader">Value/Limit</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="coverageOnlyList.length && noCoverageList.length; else alternateContent">
                    <ng-container *ngFor="let coverage of coverageOnlyList">
                        <tr *ngIf="coverage.value !== 'false'" [ngStyle]="styling?.tableValue">
                          <td>{{coverage.name}}</td>
                          <td>{{coverage.value}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let coverage of noCoverageList">
                      <tr *ngIf="coverage.value !== 'false'" [ngStyle]="styling?.tableValue">
                        <td>{{coverage.name}}</td>
                        <td>{{coverage.value}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-template #alternateContent>
                    <tr>
                      <td colspan="2" class="text-center"[ngStyle]="styling?.paragraphText">No Data available.</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
      </div>

      <div class="col-lg-12 text-right">
        <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
        (click)="navToLink('home/view-policies')"
        [ngStyle]="styling?.button?.buttonNegative">
        {{'BACK_TO_POLICY' | translate}}</button>
      </div>

    </div>
</div>